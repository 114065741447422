export const state = {
    loading: false,
    error: "",
    tokens: "",
    user: {
        email: "",
        role: "",
        email_verified: false,
        profile_pic: null,
        cover_pic: null,
        images: [],
        block: false,
        id: ""
    },
    card_details: {},
    bank_details: {},
    success_msg: "",
    images: []
};
