export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const REMOVE_CARD_DETAILS = "REMOVE_CARD_DETAILS";
export const GET_BANK_DETAILS = "GET_BANK_DETAILS";
export const REMOVE_BANK_DETAILS = "REMOVE_BANK_DETAILS";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";

export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const OTP_SUCCESS = "OTP_SUCCESS";

export const SEND_VERIFY_EMAIL = "SEND_VERIFY_EMAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_CODE = "VERIFY_EMAIL_CODE";

export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

export const SET_STATE = "SET_STATE";
export const AUTH_CHECK = "AUTH_CHECK";

export const authCheck = (payload) => ({
    type: AUTH_CHECK,
    payload
});
export const logout = (payload) => ({
    type: LOGOUT,
    payload
});

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload
});

export const setLoadingFalse = (payload) => ({
    type: SET_LOADING_FALSE,
    payload
});

export const login = (payload) => ({
    type: LOGIN,
    payload
});

export const login_fail = (payload) => ({
    type: LOGIN_FAILURE,
    payload
});

export const login_success = (payload) => ({
    type: LOGIN_SUCCESS,
    payload
});

export const get_card_details = (payload) => ({
    type: GET_CARD_DETAILS,
    payload
});

export const remove_card_details = (payload) => ({
    type: REMOVE_CARD_DETAILS,
    payload
});

export const get_bank_details = (payload) => ({
    type: GET_BANK_DETAILS,
    payload
});

export const remove_bank_details = (payload) => ({
    type: REMOVE_BANK_DETAILS,
    payload
});

export const clear_accounts = (payload) => ({
    type: CLEAR_ACCOUNTS,
    payload
});

export const get_user = (payload) => ({
    type: GET_USER,
    payload
});

export const update_user = (payload) => ({
    type: UPDATE_USER,
    payload
});

export const update_user_name = (payload) => ({
    type: UPDATE_USER_NAME,
    payload
});

export const set_state = (payload) => ({
    type: SET_STATE,
    payload
});

export const register = (payload) => ({
    type: REGISTER,
    payload
});

export const register_success = (payload) => ({
    type: REGISTER_SUCCESS,
    payload
});

export const register_fail = (payload) => ({
    type: REGISTER_FAILURE,
    payload
});

export const change_pass = (payload) => ({
    type: CHANGE_PASSWORD,
    payload
});

export const forgot_pass = (payload) => ({
    type: FORGOT_PASSWORD,
    payload
});
export const forgot_pass_success = (payload) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload
});

export const otp = (payload) => ({
    type: OTP_SUCCESS,
    payload
});

export const set_new_pass = (payload) => ({
    type: SET_NEW_PASSWORD,
    payload
});

export const verify_email = (payload) => ({
    type: SEND_VERIFY_EMAIL,
    payload
});

export const send_verify_email = (payload) => ({
    type: VERIFY_EMAIL,
    payload
});

export const verify_email_code = (payload) => ({
    type: VERIFY_EMAIL_CODE,
    payload
});
