import React from "react";
import vmsg from "vmsg";
import { BsStopCircle } from "react-icons/bs";
import { MdKeyboardVoice } from "react-icons/md";
import { Modal } from "antd";
import Button from "../../../components/Forms/Button";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import { upload_media } from "../../../Store/utils/Cloudinary";

const recorder = new vmsg.Recorder({
    wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

export default class VoiceMsg extends React.Component {
    state = {
        isLoading: false,
        isRecording: false,
        recordings: [],
        file: null,
        modal: false,
        voice_count: 0,
        interval: null,
    };
    record = async () => {
        this.setState({
            recordings: [],
            isLoading: true,
        });
        if (this.state.isRecording) {
            const blob = await recorder.stopRecording();
            clearInterval(this.state.interval);
            this.setState({
                isLoading: false,
                isRecording: false,
                voice_count: 0,
                recordings: this.state.recordings.concat(URL.createObjectURL(blob)),
                modal: true,
            });
            this.props.set_is_record(false);
            ToastTopRight.fire({
                icon: "success",
                text: "Recording Stopped!",
            });
        } else {
            try {
                await recorder.initAudio();
                await recorder.initWorker();
                recorder.startRecording();
                this.setState({ isLoading: false, isRecording: true });
                this.props.set_is_record(true);
                ToastTopRight.fire({
                    icon: "success",
                    text: "Recording Started!",
                });
                if (this.props.user.role !== "fan") {
                    this.setState({
                        interval: setInterval(async () => {
                            this.setState({
                                voice_count: this.state.voice_count + 1,
                            });
                            if (this.state.voice_count > 50) {
                                const blob = await recorder.stopRecording();
                                clearInterval(this.state.interval);

                                this.setState({
                                    isLoading: false,
                                    isRecording: false,
                                    voice_count: 0,
                                    recordings: this.state.recordings.concat(URL.createObjectURL(blob)),
                                    modal: true,
                                });
                                this.props.set_is_record(false);
                                ToastTopRight.fire({
                                    icon: "success",
                                    text: "Recording can not be exceeded from 50 seconds!",
                                });
                                clearInterval(this.state.interval);
                            }
                        }, 1000),
                    });
                }
            } catch (e) {
                console.error(e);
                this.setState({ isLoading: false });
            }
        }
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    cancel = (interval) => {
        // clearInterval(interval);
        this.setState({
            isLoading: false,
            isRecording: false,
            // voice_count: 0,
            recordings: [],
            modal: false,
        });
        this.props.set_is_record(false);
        this.closeModal();
    };

    blobToImage = (blob) => {
        return fetch(blob)
            .then((res) => res.blob())
            .then((myBlob) => {
                const myFile = new File([myBlob], "image.jpeg", {
                    type: "image/jpeg",
                });

                return myFile;
                // logs: Blob { size: 1024, type: "image/jpeg" }
            });
    };

    getVoiceUrl = async () => {
        try {
            if (this.state.recordings.length) {
                const file = await this.blobToImage(this.state.recordings);
                const form = new FormData();
                form.append("file", file);
                let payload = {
                    data: form,
                    success: () => {},
                    failure: (error) => {
                        ToastTopRight.fire({
                            icon: "error",
                            title: error.msg,
                        });
                    },
                };
                const { data } = await upload_media(payload);
                this.props.send("voice", data.file);
                this.closeModal();
            }
        } catch (e) {
            ToastTopRight.fire({
                icon: "error",
                title: e.response?.data?.message ? e.response?.data?.message : "Something Went Wrong!",
            });
        }
    };
    render() {
        const { isRecording, recordings, voice_count } = this.state;
        // const { send } = this.props;
        return (
            <React.Fragment>
                {isRecording ? (
                    <div className="">
                        <BsStopCircle color="red" size={"24px"} onClick={this.record} />
                        {voice_count > 0 && (
                            <span
                                style={{
                                    marginLeft: "5px",
                                    fontSize: "12px",
                                }}
                            >{`${voice_count}/50`}</span>
                        )}
                    </div>
                ) : (
                    <MdKeyboardVoice size={"24px"} onClick={this.record} />
                )}

                {/* <button disabled={isLoading} onClick={this.record}>
          {isRecording ? "Stop" : "Record"}
        </button> */}

                <Modal className="" footer={false} open={this.state.modal} onCancel={this.closeModal}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Recorded Audio</h3>
                            </div>
                            <div className="col-md-12 mt-3 text-center">
                                {recordings.map((url) => (
                                    <li key={url}>
                                        <audio src={url} controls />
                                    </li>
                                ))}
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Button className={"btn w-100 m-2"} onClick={() => this.cancel()}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-sm-6">
                                        <Button
                                            className={"btn btn-primaryy w-100 m-2"}
                                            onClick={() => this.getVoiceUrl()}
                                        >
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <ul style={{ listStyle: "none", padding: 0 }}>
          {recordings.map((url) => (
            <li key={url}>
              <audio src={url} controls />
            </li>
          ))}
        </ul> */}
            </React.Fragment>
        );
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         signIn: () => dispatch(signIn())
//     }
// };
// export default connect(null, mapDispatchToProps)(VoiceMsg)
