import React from "react";
import { useNavigate } from "react-router-dom";
import Fan from "../../assets/images/Fan_Boy2.png";
import Influencer from "../../assets/images/Influencer_Chick.png";
import Button from "../Forms/Button";
import Wrappper from "./Wrapper";
// import "../../index";
export default function Header() {
  const navigate = useNavigate();

  const goto = (type) => {
    navigate("/signup", { state: { type } });
  };
  return (
    <Wrappper>
      <div class="container-fluid">
        <div class="row d-flex align-items-center header-div">
          <div className="col-lg-6 col-md-12 col-sm-12  p-0 Col_div">
            <Button
              link="/signup"
              className={"influencer-btn "}
              onClick={() => goto(2)}
            >
              Influencer
            </Button>
            <img
              src={Influencer}
              alt={"Influencer"}
              className="influencer_img"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12  p-0 Col_div">
            <Button
              link="/signup"
              className={"influencer-btn "}
              onClick={() => goto(1)}
            >
              Fan
            </Button>
            <img src={Fan} alt={"fan"} />
          </div>
        </div>
      </div>
    </Wrappper>
  );
}
