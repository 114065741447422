import React, {useState} from 'react'

function UserForm({data, onSubmit, isEditing}) {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [values, setValues] = useState({
        name: data?.name || '',
        email: data?.email || '',
        password: data?.password || '',
        role: data?.role || 'fan',
        phone: data?.phone || '',
        address: data?.address || '',
    })

    const handleValuesChange = e => {
        const {name, value} = e.target
        console.log({values})
        setValues(prevValues => {
            return {...prevValues, [name]: value}
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setIsSubmitting(true)
        await onSubmit(values, isEditing)
        setIsSubmitting(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div class='form-group mb-2'>
                <label for='name'>Name</label>
                <input
                    type='text'
                    class='form-control'
                    id='name'
                    name='name'
                    placeholder='John Doe'
                    value={values.name}
                    onChange={handleValuesChange}
                />
            </div>
            {isEditing === false && (
                <>
                    <div class='form-group mb-2'>
                        <label for='email'>Email</label>
                        <input
                            type='email'
                            class='form-control'
                            id='email'
                            name='email'
                            placeholder='Email'
                            value={values.email}
                            onChange={handleValuesChange}
                        />
                    </div>
                    <div class='form-group mb-2'>
                        <label for='password'>Password</label>
                        <input
                            type='password'
                            class='form-control'
                            id='password'
                            name='password'
                            placeholder='Password'
                            value={values.password}
                            onChange={handleValuesChange}
                        />
                    </div>
                </>
            )}

            <div class='form-group mb-2'>
                <label for='role'>Role</label>
                <select
                    id='role'
                    name='role'
                    class='form-control'
                    value={values.role}
                    onChange={handleValuesChange}
                >
                    <option selected value={'fan'}>
                        Fan
                    </option>
                    <option value={'influencer'}>Influencer</option>
                    <option value={'admin'}>Admin</option>
                </select>
            </div>
            <div class='form-group mb-2'>
                <label for='phone'>Phone</label>
                <input
                    type='text'
                    class='form-control'
                    id='phone'
                    name='phone'
                    placeholder='Type user phone number'
                    value={values.phone}
                    onChange={handleValuesChange}
                />
            </div>

            <div class='form-group mb-2'>
                <label for='address'>Address</label>
                <input
                    type='text'
                    class='form-control'
                    id='address'
                    name='address'
                    placeholder='Type user address'
                    value={values.address}
                    onChange={handleValuesChange}
                />
            </div>
            {isEditing ? (
                <button
                    type='submit'
                    class='btn btn-primary'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Editing...' : 'Edit User'}
                </button>
            ) : (
                <button
                    type='submit'
                    class='btn btn-primary'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Creating...' : 'Create User'}
                </button>
            )}
        </form>
    )
}

export default UserForm
