import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../../../assets/images/Frame 16.png";
import { login, setLoading } from "../../../Store/Reducers/Auth/actions";
import { getUser } from "../../../Store/selectors";
import Spinner from "../../Spinner";
import { ToastTopRight } from "../../Toasts/top-right";
import Wrappper from "../Wrapper";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(getUser);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);
  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setData({ ...data, [name]: value });
  };
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  // validation
  const clearErrors = () => {
    errField.name = "";
    errField.email = "";
    errField.password = "";
    setErrField(errField);
  };
  const checkValidation = () => {
    clearErrors();

    var error = false;
    const errForm = { email: "", password: "" };
    /* eslint-disable */
    Object.keys(data).map(function (keyName, keyIndex) {
      const value = data[keyName];

      if (keyName === "email") {
        if (value === "") {
          errForm.email = "ⓘ Email Address Is Required!";
          error = true;
        } else if (!validateEmail(value)) {
          errForm.email = "ⓘ Invalid Email Address!";
          error = true;
        }
      }

      if (keyName === "password") {
        if (value === "") {
          errForm.password = "ⓘ Password Is Required!";
          error = true;
        } else if (value.length > 16 || value.length < 8) {
          errForm.password =
            "ⓘ Password Should Be Between 8 And 16 Characters!";
          error = true;
        }
      }

      setErrField(errForm);
    });
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (checkValidation()) {
      dispatch(setLoading(false));
      return;
    }

    let payload = {
      ...data,
      success: () => {
        ToastTopRight.fire({
          icon: "success",
          title: "LoggedIn Successfully!",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
        dispatch(setLoading(false));
      },
      failure: (error) => {
        ToastTopRight.fire({
          icon: "error",
          title: error.msg,
        });
        dispatch(setLoading(false));
      },
    };
    dispatch(login(payload));
  };
  return (
    <Wrappper>
      <nav class="navbar navbar-expand-lg navbar-light ">
        <div class="container">
          <a class="navbar-brand" href="#">
            <div className="logoImg">
              <img src={LogoImg} alt={"logoImg"} />
              {/* <p>KNOWEM.COM</p> */}
            </div>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#"></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#"></a>
              </li>

              <li class="nav-item"></li>
            </ul>
            <form
              class="d-flex navbar-form"
              method="post"
              onSubmit={handleSubmit}
            >
              <input
                class="form-control me-2 "
                type="email"
                name="email"
                placeholder="your@email.com"
                aria-label="Search"
                value={data.email}
                onChange={handleInput}
              />
              {/* <div className="d-flex"> */}
              <input
                class="form-control me-2"
                type="password"
                name="password"
                placeholder="********"
                aria-label="Search"
                value={data.password}
                onChange={handleInput}
              />
              {loading ? (
                <Spinner height={"20px"} width={"20px"} />
              ) : (
                <button class="btn btn-outline-light btn-submit" type="submit">
                  Submit
                </button>
              )}
              {/* </div> */}
            </form>
          </div>
        </div>
      </nav>
    </Wrappper>
  );
}
