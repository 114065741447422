import { put, call, takeLatest, delay, select } from "redux-saga/effects";
import { ErrorHandler } from "../../utils/errorHandler";
import * as actions from "./actions";
import {
    getCardDetail,
    getForgotPass,
    getLogin,
    getLogout,
    getRegister,
    getUpdateUserDetail,
    getUpdateUserName,
    getUserDetail,
    get_auth_check,
    get_otp_success,
    get_send_verify_email,
    get_set_new_pass,
    get_set_user_details,
    get_verify_email,
    get_verify_email_code,
    postChangePassword,
    removeCardDetail,
} from "./services";
import { getUser } from "../../selectors";
import { removeBankDetail } from "./services";
import { set_chats } from "../Dashboard/actions";
function* __AuthCheck(action) {
    try {
        // const user = yield select(getUser);
        let res = yield get_auth_check();
    } catch (e) {
        yield put(actions.logout());
        action.payload.navigate("/");
    }
}

function* __GETUSER(action) {
    try {
        var payload = {
            user: {},
            card_details: {},
        };
        const { user } = yield select(getUser);
        let res = yield getUserDetail(user.id);
        payload.user = res.data.user;
        if (user.role === "fan") {
            let card = yield getCardDetail();
            payload.card_details = card.data.card;
        }
        yield put(actions.login_success(payload));
    } catch (e) {
        // yield put(actions.logout());
        // action.payload.navigate("/");
    }
}

function* __Logout(action) {
    yield getLogout();
    if (action?.payload?.navigate) {
        action.payload.navigate("/");
    }
}
function* __Login(action) {
    try {
        var payload = {
            tokens: "",
            user: {},
            card_details: {},
        };
        const res = yield getLogin(action.payload);
        if (res) {
            payload.tokens = res.data.tokens;
            payload.user = res.data.user;
            localStorage.setItem("bearer", res.data.tokens?.access?.token);
            // let card = yield getCardDetail();
            // payload.card_details = card.data.card;
            yield put(actions.login_success(payload));
            action.payload.success(res.data);
            yield put(actions.setLoading(false));
            yield put(set_chats([]));
        }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        action.payload.failure(errorMessage);
        yield put(actions.setLoading(false));
    }
}
function* __REGISTER(action) {
    try {
        var payload = {
            tokens: "",
            user: {},
        };

        const res = yield getRegister(action.payload);
        if (res.status === 201) {
            payload.tokens = res.data.tokens;
            payload.user = res.data.user;
            localStorage.setItem("bearer", res.data.tokens?.access?.token);
        }
        yield put(actions.login_success(payload));
        action.payload.success(true);
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        action.payload.failure(errorMessage);
    }
}

function* __GETCARDDETAILS(action) {
    try {
        var payload = {
            card_details: {},
        };
        const res = yield getCardDetail();

        if (res.status === 200) {
            payload.card_details = res.data.card;
        }
        yield put(actions.login_success(payload));
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        if (errorMessage.code === 400) {
            yield put(actions.clear_accounts());
        }
    }
}

function* __REMOVECARDDETAILS(action) {
    try {
        var payload = {
            card_details: {},
        };
        yield removeCardDetail();
        yield put(actions.login_success(payload));
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        console.log({ errorMessage });
    }
}

function* __GETBANKDETAILS(action) {
    try {
        var payload = {
            bank_details: {},
        };
        const res = yield getCardDetail();
        if (res.status === 200) {
            payload.bank_details = res.data.card;
            payload.status = true;
            if (res.data.stripeAccount) {
                if (
                    res.data.stripeAccount.capabilities?.card_payments === "inactive" ||
                    res.data.stripeAccount.capabilities?.transfers === "inactive"
                ) {
                    payload.bank_details.status = false;
                    payload.bank_details.connectLink = res.data.accountLink?.url;
                } else {
                    payload.bank_details.status = true;
                    payload.bank_details.connectLink = null;
                }
            }
        }
        yield put(actions.login_success(payload));
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        if (errorMessage.code === 400) {
            yield put(actions.clear_accounts());
            // yield put(set_chats([]));
        }
    }
}

function* __REMOVEBANKDETAILS(action) {
    try {
        var payload = {
            bank_details: {},
        };
        yield removeBankDetail();
        yield put(actions.login_success(payload));
    } catch (e) {
        console.log({ e });
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __CLEARACCOUNTS(action) {
    try {
        var payload = {
            bank_details: {},
            card_details: {},
        };
        yield put(actions.login_success(payload));
    } catch (e) {}
}
function* __UPDATEUSER(action) {
    try {
        var payload = {
            user: {},
        };
        const { data } = action.payload;
        const { user } = yield select(getUser);
        const res = yield getUpdateUserDetail(user.id, data);

        if (res.status === 200) {
            payload.user = res.data.user;
            // let card = yield getCardDetail();
            // payload.user.card_details = card.data.card;
            action.payload.success();
        }
        yield put(actions.login_success(payload));
    } catch (e) {
        console.log({ e });
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        action.payload.failure(errorMessage);
    }
}
function* __UPDATEUSERNAME(action) {
    try {
        var payload = {
            user: {},
        };
        const { data } = action.payload;
        const { user } = yield select(getUser);
        const res = yield getUpdateUserName(user.id, data);

        if (res.status === 200) {
            payload.user = res.data.user;
            action.payload.success(res.data.user);
        }
        yield put(actions.login_success(payload));
    } catch (e) {
        console.log({ e });
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        action.payload.failure(errorMessage);
    }
}

function* __CHANGEPASS(action) {
    try {
        yield postChangePassword(action.payload);
        action.payload.success(true);
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
        action.payload.error(errorMessage);
    }
}

function* __ForgotPass(action) {
    try {
        const res = yield getForgotPass(action.payload);
        if (res.status === 200) {
            const data = res.data;
            if (data.code) {
                action.payload.navigate("/verify-otp", {
                    state: { code: data.code, email: action.payload.email },
                });
            }
            yield put(actions.SET_LOADING_FALSE);
        }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __SENDVERIFYEMAIL(action) {
    try {
        const res = yield get_send_verify_email(action.payload);
        if (res.status === 200) {
            yield put(
                actions.login_success({
                    success_msg: "Verification Email Sent Successfully!",
                    loading: false,
                    error: "",
                }),
            );
        }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __VERIFYEMAIL(action) {
    try {
        yield get_verify_email(action.payload);
        delay(500);
        yield put(
            actions.login_success({
                success_msg: "Email Verified Successfully!",
                loading: false,
                error: "",
            }),
        );
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __VERIFYEMAILCODE(action) {
    try {
        yield get_verify_email_code(action.payload);
        yield put(
            actions.login_success({
                success_msg: "Account Verified Successfully!",
                loading: false,
                error: "",
            }),
        );
        delay(500);
        setTimeout(() => {
            action.payload.navigate("/dashboard");
        }, 2000);
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __OtpSuccess(action) {
    try {
        yield get_otp_success(action.payload);
        // if (res.status === 200) {
        delay(500);
        action.payload.navigate("/set-new-password", {
            state: { code: action.payload.code, email: action.payload.email },
        });
        // }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

function* __SetNewPass(action) {
    try {
        const res = yield get_set_new_pass(action.payload);
        if (res.status === 200) {
            const data = res.data;
            yield put(
                actions.login_success({
                    success_msg: data.message,
                    loading: false,
                    error: "",
                }),
            );
            action.payload.navigate("/");
        }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.login_fail({ error: errorMessage }));
    }
}

export function* login() {
    yield takeLatest(actions.LOGIN, __Login);
}

export function* authCheck() {
    yield takeLatest(actions.AUTH_CHECK, __AuthCheck);
}

export function* logout() {
    yield takeLatest(actions.LOGOUT, __Logout);
}

export function* register() {
    yield takeLatest(actions.REGISTER, __REGISTER);
}

export function* get_user_saga() {
    yield takeLatest(actions.GET_USER, __GETUSER);
}

export function* update_user() {
    yield takeLatest(actions.UPDATE_USER, __UPDATEUSER);
}
export function* update_user_name() {
    yield takeLatest(actions.UPDATE_USER_NAME, __UPDATEUSERNAME);
}

export function* getCardDetails() {
    yield takeLatest(actions.GET_CARD_DETAILS, __GETCARDDETAILS);
}

export function* removeCardDetails() {
    yield takeLatest(actions.REMOVE_CARD_DETAILS, __REMOVECARDDETAILS);
}

export function* getBankDetails() {
    yield takeLatest(actions.GET_BANK_DETAILS, __GETBANKDETAILS);
}

export function* removeBankDetails() {
    yield takeLatest(actions.REMOVE_BANK_DETAILS, __REMOVEBANKDETAILS);
}

export function* clearAccounts() {
    yield takeLatest(actions.CLEAR_ACCOUNTS, __CLEARACCOUNTS);
}

export function* change_password() {
    yield takeLatest(actions.CHANGE_PASSWORD, __CHANGEPASS);
}

export function* forgotPass() {
    yield takeLatest(actions.FORGOT_PASSWORD, __ForgotPass);
}

export function* otp_success() {
    yield takeLatest(actions.OTP_SUCCESS, __OtpSuccess);
}

export function* send_verify_email() {
    yield takeLatest(actions.SEND_VERIFY_EMAIL, __SENDVERIFYEMAIL);
}

export function* verify_email() {
    yield takeLatest(actions.VERIFY_EMAIL, __VERIFYEMAIL);
}

export function* verify_email_code() {
    yield takeLatest(actions.VERIFY_EMAIL_CODE, __VERIFYEMAILCODE);
}

export function* set_new_pass() {
    yield takeLatest(actions.SET_NEW_PASSWORD, __GETUSER);
}
