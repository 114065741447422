import * as actions from "./actions";

const init = {
    users: [],
    error: "",
    success_mss: "",
    loading: false,
    chat_loading: false,
    packages: [],
    chats: [],
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case actions.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
                error: "",
                success_msg: "",
            };
        }

        case actions.SET_CHATLOADING: {
            return {
                ...state,
                chat_loading: action.payload,
                error: "",
                success_msg: "",
            };
        }
        case actions.GET_USERS: {
            return {
                ...state,
                loading: true,
                error: "",
                success_msg: "",
            };
        }

        case actions.SET_USERS: {
            return {
                ...state,
                loading: false,
                users: action.payload,
                error: "",
                success_msg: "",
            };
        }

        case actions.SET_PACKAGES: {
            return {
                ...state,
                loading: false,
                error: "",
                success_msg: "",
                packages: action.payload,
            };
        }

        case actions.SET_CHATS: {
            return {
                ...state,
                loading: false,
                chats: action.payload,
                error: "",
                success_msg: "",
            };
        }

        case actions.SET_ERROR: {
            return {
                ...state,
                error: action.payload.error,
            };
        }

        default:
            return state;
    }
};

export default reducer;
