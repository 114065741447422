import React, { useEffect, useRef, useState } from "react";
import { IoIosNotifications } from "react-icons/io";
import { ImSwitch } from "react-icons/im";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogoImg from "../../assets/images/Frame 16.png";

import { logout, LOGOUT } from "../../Store/Reducers/Auth/actions";
import { getDashboard, getUser } from "../../Store/selectors";
import { get_chats } from "../../Store/Reducers/Dashboard/actions";
import { AiOutlineLink } from "react-icons/ai";
import { ToastTopRight } from "../Toasts/top-right";
import { basePath, getBearerToken } from "../../Store/Config/config";
import axios from "axios";

function TopBar(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector(getUser);
    const { chats } = useSelector(getDashboard);
    const [count, setCount] = useState(0);
    const [platformEarning, setPlatformEarning] = useState(0);
    const [loadingPlatformEarning, setLoadingPlatformEarning] = useState(true);

    useEffect(() => {
        fetchPlatformEarnings();
        closeNavbar();
    }, [location.pathname]);

    const closeNavbar = () => {
        if (window.innerWidth <= 992) {
            const navbarCollapse = document.querySelector("#navbarNav");
            const navbarToggler = document.querySelector(".navbar-toggler");
            // Check if the navbar is currently open
            if (navbarCollapse.classList.contains("show")) {
                // Close the navbar by removing the "show" class
                navbarCollapse.classList.remove("show");
                // Toggle the state of the navbar toggler button
                navbarToggler.setAttribute("aria-expanded", "false");
            }
        }
    };

    const fetchPlatformEarnings = async () => {
        setLoadingPlatformEarning(true);
        try {
            const response = await axios.get(`earnings/total`, {
                headers: {
                    Authorization: `Bearer ${await getBearerToken()}`,
                },
            });
            setPlatformEarning(response?.data?.platformEarnings);
            setLoadingPlatformEarning(false);
        } catch (error) {
            console.error(error);
            setLoadingPlatformEarning(false);
        }
    };

    const signout = () => {
        dispatch(logout({ navigate }));
    };

    useEffect(() => {
        getChats();
        setInterval(() => {
            getChats();
        }, 30000);
    }, []);

    const getChats = () => {
        dispatch(get_chats());
    };

    useEffect(() => {
        checkUnRepliedMessages();
    }, [chats]);

    const checkUnRepliedMessages = () => {
        const tempChats = chats.map((item) => {
            const last_msg = item.messages[item.messages.length - 1];
            if (last_msg) {
                const now = new Date();
                const last_msg_date = new Date(last_msg.timestamp);
                const endDate = new Date(last_msg_date.getTime() + 15 * 60 * 60 * 1000);
                if (endDate > now.getTime() && last_msg.responded === false) {
                    item.unreplied = true;
                } else {
                    item.unreplied = false;
                }
            } else {
            }

            return item;
        });
        setCount(tempChats.filter((i) => i.unreplied).length);
    };

    const componentRef = useRef(null);
    if (componentRef.current) {
        const height = componentRef.current.clientHeight;
    }

    const copyLink = () => {
        ToastTopRight.fire({
            icon: "success",
            text: "Link Copied!",
        });
        navigator.clipboard.writeText(window.location.hostname + "/" + user.username);
    };

    return (
        <Wrapper>
            <nav class="navbar navbar-expand-lg navbar-light" ref={componentRef}>
                <div class="container-fluid">
                    <Link class="navbar-brand" to="/dashboard">
                        <div className="logoImg">
                            <img src={LogoImg} alt={"logoImg"} />
                            {/* <p>KNOWEM.COM</p> */}
                        </div>
                    </Link>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item"></li>
                            <li class="nav-item"></li>
                            <li class="nav-item"></li>
                            <li class="nav-item"></li>
                        </ul>
                        <form class="d-flex">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                {user.role === "influencer" && (
                                    <>
                                        <span
                                            style={{
                                                color: "white",
                                                fontSize: "20px",
                                            }}
                                        >
                                            {window.location.hostname + "/" + user.username}
                                        </span>
                                        <AiOutlineLink
                                            color="white"
                                            size={"30px"}
                                            onClick={copyLink}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        />{" "}
                                        <li class="nav-item">
                                            <Link class="nav-link mx-2" to="#">
                                                Balance{" "}
                                                <span
                                                    className="strong"
                                                    style={{
                                                        border: "1px solid white",
                                                        padding: "3px 5px",
                                                        borderRadius: "3px",
                                                    }}
                                                >
                                                    $ {user?.balance?.toFixed(2)}
                                                </span>
                                            </Link>
                                        </li>
                                    </>
                                )}
                                {user.role === "fan" && (
                                    <li class="nav-item">
                                        <Link class="nav-link mx-2" to="#">
                                            Credit{" "}
                                            <span
                                                className="strong"
                                                style={{
                                                    border: "1px solid white",
                                                    padding: "3px 5px",
                                                    borderRadius: "3px",
                                                }}
                                            >
                                                $ {user?.credits?.toFixed(2)}
                                            </span>
                                        </Link>
                                    </li>
                                )}
                                {user.role === "fan" && (
                                    <li class="nav-item">
                                        <Link class="nav-link mx-2" to="/dashboard">
                                            <i class="fas fa-plus-circle pe-2"></i>
                                            Find <span className="strong">Influencers</span>
                                        </Link>
                                    </li>
                                )}
                                {user.role === "admin" && (
                                    <>
                                        <li class="nav-item">
                                            <Link class="nav-link mx-2" to="#">
                                                Earnings{" "}
                                                <span
                                                    className="strong"
                                                    style={{
                                                        border: "1px solid white",
                                                        padding: "3px 5px",
                                                        borderRadius: "3px",
                                                    }}
                                                >
                                                    $ {loadingPlatformEarning ? "Loading..." : `${platformEarning}`}
                                                </span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link class="nav-link mx-2" to="/admin/dashboard">
                                                <i class="fas fa-plus-circle pe-2"></i>
                                                <span className="strong">Dashboard</span>
                                            </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link class="nav-link mx-2" to="/admin/dashboard/earnings">
                                                <i class="fas fa-plus-circle pe-2"></i>
                                                <span className="strong">Statistics</span>
                                            </Link>
                                        </li>
                                    </>
                                )}
                                {/* <li class="nav-item">
                  <Link class="nav-link mx-2" to="/dashboard/voicemsg">
                    VoiceMsg
                  </Link>
                </li> */}
                                {user?.role !== "admin" && (
                                    <li class="nav-item">
                                        <Link class="nav-link mx-2" to="/dashboard/messages">
                                            {user?.role !== "fan" && (
                                                <span
                                                    style={{
                                                        border: "1px solid grey",
                                                        borderRadius: "50%",
                                                        padding: "3px 5px",
                                                        background: "yellow",
                                                        color: "black",
                                                    }}
                                                >
                                                    {count}
                                                </span>
                                            )}
                                            <IoIosNotifications style={{ fontSize: "24px" }} />
                                            Messages
                                        </Link>
                                    </li>
                                )}

                                <li class="nav-item dropdown">
                                    <Link
                                        class="nav-link dropdown-toggle"
                                        to="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {user.name}
                                    </Link>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                        {user?.role !== "admin" && (
                                            <li>
                                                <Link class="dropdown-item" to={`/dashboard/${user.username}`}>
                                                    Profile
                                                </Link>
                                            </li>
                                        )}
                                        {user?.role !== "admin" && (
                                            <li>
                                                <Link class="dropdown-item" to="/dashboard/wallet">
                                                    Wallet
                                                </Link>
                                            </li>
                                        )}

                                        <li>
                                            {user?.role === "admin" ? (
                                                <Link class="dropdown-item" to="/admin/dashboard/change-password">
                                                    Change password
                                                </Link>
                                            ) : (
                                                <Link class="dropdown-item" to="/dashboard/change-password">
                                                    Change password
                                                </Link>
                                            )}
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link class="dropdown-item" onClick={signout}>
                                                <ImSwitch color="red" /> Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item"></li>
                            </ul>
                        </form>
                    </div>
                </div>
            </nav>
        </Wrapper>
    );
}

export default TopBar;

const Wrapper = styled.div`
    /* width: 100%; */
    nav {
        background-color: #046d68 !important;

        .logoImg {
            width: auto;
            height: 60px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        li {
            a {
                color: #fbfcfc !important;
                .strong {
                    font-weight: bold;
                    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
                    color: #fbfcfc !important;
                }
            }
        }
        .nav-item.dropdown {
            min-width: 120px;
            display: flex;
            justify-content: center;
        }
        .dropdown-menu {
            min-width: 9rem !important;
            .dropdown-item {
                color: var(--color-light) !important;
            }
        }
    }
    @media screen and (max-width: 335px) {
        .logoImg {
            width: 150px !important;
            height: 0px;
        }
        .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-brand {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
`;
