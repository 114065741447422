import React from "react";
import Wrappper from "./Wrapper";
// import "../../index";
export default function Footer() {
  return (
    <Wrappper>
      <div class="container-fluid Footer">
        <div class="row">
          <div class="text-start col-12 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center">
            <h5>ABOUT</h5>
            <p>
              iKnowEm empowers you to hear
              <br /> from those who matter to you.
            </p>
          </div>
        </div>
      </div>
    </Wrappper>
  );
}
