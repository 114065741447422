import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Dashboard from "./Dashboard/reducer";

const rootReducer = combineReducers({
    Auth,
    Dashboard,
});

export default rootReducer;
