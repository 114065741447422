import React from "react";
import PropTypes from "prop-types";
import {Wrapper, Inputitem, SecondWrapper, SecondInputitem} from "./styled";

const Input = ({
    type,
    placeholder,
    span,
    paddingright,
    firsticon,
    secondicon,
    rightImgWidth,
    className,
    onClick,
    onChange,
    onInput,
    name,
    value,
    appearance
}) => {
    return appearance === 'second' ? (
        <SecondWrapper>
            {rightImgWidth ? (
                <span className="leftIcon">
                    <firsticon />
                </span>
            ) : null}
            <SecondInputitem
                name={name}
                className={className}
                type={type}
                placeholder={placeholder}
                paddingright={paddingright}
                onChange={onChange}
                onInput={onInput}
                value={value}
            />
            {span && (
                <span className="rightIcon" onClick={onClick}>
                    {rightImgWidth ? (
                        <img
                            src={secondicon}
                            alt="icon"
                            width={rightImgWidth}
                        />
                    ) : (
                        <img src={secondicon} alt="icon" />
                    )}
                </span>
            )}
        </SecondWrapper>
    ) : (
        <Wrapper>
            {rightImgWidth ? (
                <span className="leftIcon">
                    <firsticon />
                </span>
            ) : null}
            <Inputitem
                name={name}
                className={className}
                type={type}
                placeholder={placeholder}
                paddingright={paddingright}
                onChange={onChange}
                onInput={onInput}
                value={value}
            />
            {span && (
                <span className="rightIcon" onClick={onClick}>
                    {rightImgWidth ? (
                        <img
                            src={secondicon}
                            alt="icon"
                            width={rightImgWidth}
                        />
                    ) : (
                        <img src={secondicon} alt="icon" />
                    )}
                </span>
            )}
        </Wrapper>
    );
};

Input.propTypes = {
    onclick: PropTypes.func,
};

export default Input;
