import React, { useState } from "react";
import Wrapper from "./Wrapper";
import { Modal } from "antd";
import faaan from "../../../assets/images/faaan.jpg";
import MessageBox from "../../../components/MessageBox/Index";
import Button from "../../../components/Forms/Button";
export default function Packages({ handleCcancel, isModalVisible, packages, pkgPurchase }) {
    const [pkg, setPkg] = useState(null);

    const purchase = (item) => {
        pkgPurchase({ packageId: item.id });
    };
    return (
        <Modal title="PRICING OVERVIEW" open={isModalVisible} onCancel={handleCcancel} footer={false}>
            <Wrapper>
                <div class="container">
                    <div class="row my-5">
                        {packages.map((item, index) => {
                            return (
                                <div class="col-lg-6 col-md-6 col-sm-6 ">
                                    <Button
                                        className={"submit"}
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            purchase(item);
                                        }}
                                    >
                                        Buy ${item.credits}
                                    </Button>
                                </div>

                                // <div class="col-lg-12 col-md-12 col-sm-12 ">
                                //   <div
                                //     className={
                                //       pkg === item.id
                                //         ? "effect p-3 m-1  active"
                                //         : " effect shadow p-3 m-1"
                                //     }
                                //     onClick={() => setPkg(item.id)}
                                //   >
                                //     <li class="pricing-overview__price-plan price-plan price-plan--faded">
                                //       <header class="price-plan__header">
                                //         <h3 class="price-plan__title">
                                //           {index === 1 ? "Standard" : "Premium"}
                                //         </h3>

                                //         <p class="price-plan__blurb">{item.description}</p>
                                //       </header>

                                //       <div class="price-plan__pricing">
                                //         <p class="price-plan__annual-pricing">
                                //           <strong class="price-plan__annual-price">
                                //             ${item.price}
                                //           </strong>
                                //           /Message
                                //         </p>
                                //       </div>

                                //       <ul class="price-plan__features">
                                //         <li class="price-plan__feature">
                                //           get guaranteed response
                                //         </li>
                                //         <li class="price-plan__feature">send voice messages</li>
                                //         {index === 1 && (
                                //           <li class="price-plan__feature">
                                //             save
                                //             <strong class="price-plan__annual-price">1$</strong>
                                //           </li>
                                //         )}{" "}
                                //       </ul>
                                //     </li>
                                //   </div>
                                // </div>
                            );
                        })}

                        {/* <Button
              className={"submit"}
              onClick={() => {
                purchase();
              }}
            >
              Purchase
            </Button> */}
                    </div>
                </div>
            </Wrapper>
        </Modal>
    );
}
