import styled from "styled-components";

const Wrappper = styled.div`
  .navbar {
    background-color: #b7b7b7 !important;
    color: #ffffff;

    form {
      input {
        background-color: transparent;
        border: 3px solid #ffffff;
        outline-style: solid;
        outline-color: #ffffff;
      }
    }
  }
  .logoImg {
    width: auto;
    height: 70px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btn-submit {
    background-color: white !important;
    font-size: 12px;
    font-weight: 500;
    color: black !important;
  }

  .header-div {
    /* height: 80vh; */
    .Col_div {
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 50%; */
      min-height: auto;
      img {
        width: 100%;
        height: 100%;
        background-position: 100% 100%;
      }
      .influencer_img {
        border: 10px solid #ff0095;
      }
      .influencer-btn {
        text-align: center;
        display: inline-block;
        position: relative;
        overflow: hidden;
        background-color: #e91d63 !important;
        padding: 10px 40px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        position: absolute;
        color: black !important;
      }
      .influencer-btn:before {
        content: "";
        position: absolute;
        height: 150px;
        width: 50px;
        background: #fff;
        right: -40px;
        top: -40px;
        transform: rotate(37deg);
        transition: all 0.3s;
        opacity: 0.3;
      }

      .influencer-btn:hover:before {
        right: 105%;
      }
    }
  }
  .Footer {
    background-color: #47b0a1;
    padding: 10px 100px;
    color: #cdf6f4;
    box-sizing: border-box;
  }
  @media screen and (max-width: 967px) and (min-width: 237px) {
    .navbar-form {
      padding: 0px 0px;
      input {
        margin: 0px 8px;
      }
    }
    .header-div {
      .influencer-btn {
        padding: 6px 14px !important;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .Footer {
      padding: 10px 30px;
    }
    .logoImg {
      width: 150px;
      /* height: 83px; */
    }
  }
`;
export default Wrappper;
