import Axios from "axios";

export const getBearerToken = async () => {
    var state = await JSON.parse(localStorage.getItem("persist:root"));
    var token = await localStorage.getItem("bearer");
    return token;
};

export const basePath = process.env.NODE_ENV === 'production' ? '/v1/' :  'http://localhost:8080/v1/';
// export const basePath = 'https://i-know-em-backend.vercel.app/v1/'
// export const basePath = "http://18.232.94.161:8080/v1/";
// export const basePath = "https://iknowem.wizzbyte.com/v1/";
Axios.defaults.baseURL = basePath;
Axios.defaults.headers.common["Authorization"] = `Bearer ${getBearerToken()}`;
const SocketPath = process.env.NODE_ENV === 'production' ? 'ws://' :  'ws://localhost:8080';
// const SocketPath = "wss://api.brightconceptpk.com";


Axios.interceptors.request.use(
    async (config) => {
        const token = `Bearer ${await getBearerToken()}`;
        if (getBearerToken()) {
            config.headers["Authorization"] = token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

Axios.interceptors.response.use(
    async (response) => {
        const token = `Bearer ${await getBearerToken()}`;
        return response;
    },
    async function (error) {
        const token = `Bearer ${await getBearerToken()}`;
        const originalRequest = error.config;
        Axios.defaults.headers.common["Authorization"] = token;
        if (
            error.response.status === 401
            // &&
            // originalRequest.url === "http://127.0.0.1:3000/v1/auth/token"
        ) {
            window.history.replaceState({}, "", "/dashboard");
            // history.pus.push("/login");
            return Promise.reject(error);
        }

        // for refreshing the token

        // if (error.response.status === 401 && !originalRequest._retry) {
        //   originalRequest._retry = true;
        //   const refreshToken = localStorageService.getRefreshToken();
        //   return axios
        //     .post("/auth/token", {
        //       refresh_token: refreshToken,
        //     })
        //     .then((res) => {
        //       if (res.status === 201) {
        //         localStorageService.setToken(res.data);
        //         axios.defaults.headers.common["Authorization"] =
        //           "Bearer " + token;
        //         return axios(originalRequest);
        //       }
        //     });
        // }
        return Promise.reject(error);
    },
);

export { Axios as axios, SocketPath };
