import React, { useState } from "react";
import Card from "react-credit-cards";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get_bank_details, get_card_details, get_user } from "../../Store/Reducers/Auth/actions";
import { add_bank_details, add_card_details } from "../../Store/Reducers/Dashboard/services";
import { getUser } from "../../Store/selectors";
import Button from "../Forms/Button";
import Input from "../Forms/Input";
import { ToastTopRight } from "../Toasts/top-right";

export default function CardDetail({ card_data, setCardModal }) {
    const dispatch = useDispatch();
    const { user } = useSelector(getUser);
    const [card, setCard] = useState({
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
    });

    const [account, setAccount] = useState({
        email: "",
        country: "US",
        currency: "usd",
        routing_number: "",
        account_number: "",
        account_holder_name: "",
        account_holder_type: "individual",
    });

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === "number") {
            if (value.length > 16) {
                return;
            }
        }
        if (name === "expiry") {
            if (value.length > 5) {
                return;
            }
        }
        if (name === "cvc") {
            if (value.length > 4) {
                return;
            }
        }
        if (name === "expiry") {
            if (value.trim().length === 2 && !value.includes("/")) {
                setCard({ ...card, [name]: `${value}/` });
                return;
            }
        }
        setCard({ ...card, [name]: value });
    };

    const handleAccountChange = (e) => {
        let { name, value } = e.target;
        if (name === "country") {
            setAccount({
                ...account,
                country: value.split(",")[0],
                currency: value.split(",")[1],
            });
        }
        setAccount({ ...account, [name]: value });
    };

    const addCard = async (e) => {
        let payload = {
            email: user.email,
            card_number: card.number,
            cvc: card.cvc,
            exp_month: card.expiry.split("/")[0],
            exp_year: card.expiry.split("/")[1],
            name_as_per_card: card.name,
            billing_address: "random",
        };
        const result = await add_card_details(payload);
        if (result.error) {
            ToastTopRight.fire({
                icon: "error",
                text: result.error?.msg,
            });
        } else {
            ToastTopRight.fire({
                icon: "success",
                text: "Card Added Successfully!",
            });
            dispatch(get_user());
            dispatch(get_card_details());
            setCardModal(false);
        }
    };

    const addBank = async (e) => {
        let payload = { ...account };
        const result = await add_bank_details(payload);
        if (result.error) {
            ToastTopRight.fire({
                icon: "error",
                text: result.error?.msg,
            });
        } else {
            ToastTopRight.fire({
                icon: "success",
                text: "Bank Added Successfully!",
            });

            dispatch(get_user());
            dispatch(get_bank_details());
            setCardModal(false);
            if (result.data?.accountLink) {
                window.location.replace(result.data?.accountLink.url);
            }
        }
    };

    return (
        <Wrapper>
            <div className="row">
                {user.role === "fan" ? (
                    <>
                        <Card
                            cvc={card.cvc}
                            expiry={card.expiry}
                            focused={card.focus}
                            name={card.name}
                            number={card.number}
                        />
                        <div className="col-lg-12 mt-3">
                            <Input
                                type={"number"}
                                name="number"
                                value={card.number}
                                onChange={handleInputChange}
                                placeholder="Enter Credit/Debit Card Number"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <Input
                                type={"text"}
                                name="name"
                                value={card.name}
                                onChange={handleInputChange}
                                placeholder="Enter Full name"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <Input
                                type={"text"}
                                name="expiry"
                                value={card.expiry}
                                onChange={handleInputChange}
                                placeholder="Enter Expiry Date"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <Input
                                type={"number"}
                                name="cvc"
                                value={card.cvc}
                                onChange={handleInputChange}
                                placeholder="Enter CVC"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <Button className={"btn btn-primaryy w-100"} onClick={addCard}>
                                Add
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-lg-6 mt-3">
                            <Input
                                type={"email"}
                                name="email"
                                value={account.email}
                                onChange={handleAccountChange}
                                placeholder="Enter Billing Email"
                            />
                        </div>

                        <div className="col-lg-6 mt-3">
                            <Input
                                type={"text"}
                                name="account_holder_name"
                                value={account.account_holder_name}
                                onChange={handleAccountChange}
                                placeholder="Enter Account Holder Name"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <select className="form-control" name="country" onChange={handleAccountChange}>
                                <option value="US,usd" disabled selected>
                                    United States
                                </option>
                            </select>
                        </div>

                        <div className="col-lg-6 mt-3">
                            <Input
                                type={"number"}
                                name="routing_number"
                                value={account.routing_number}
                                onChange={handleAccountChange}
                                placeholder="Enter Routing Number"
                            />
                        </div>

                        <div className="col-lg-6 mt-3">
                            <Input
                                type={"text"}
                                name="account_number"
                                value={account.account_number}
                                onChange={handleAccountChange}
                                placeholder="Enter Account Number"
                            />
                        </div>

                        <div className="col-lg-12 mt-3">
                            <Button className={"btn btn-primaryy w-100"} onClick={addBank}>
                                Add
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    align-items: center;
    align-self: center;

    .heading {
        h2 {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            color: var(--color-dark);
            margin-bottom: 0px;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .col-lg-12 {
        text-align: center;
        input {
            width: 80% !important;
            height: 40px !important;
        }
        button {
            padding: 10px;
            width: 80% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;
