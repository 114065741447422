import { axios } from "../../Config/config.js";

const getLogin = async (payload) => {
    return await axios.post("auth/login", payload);
};

const getLogout = async () => {
    localStorage.removeItem("persist:root");
    return true;
};

const getUserDetail = async (id) => {
    return await axios.get(`users/${id}/`);
};

const getCardDetail = async () => {
    return await axios.get(`bank/get-card`);
};

const removeCardDetail = async () => {
    return await axios.post(`bank/remove-card`);
};

const getBankDetail = async () => {
    return await axios.get(`bank/get-bank`);
};

const removeBankDetail = async () => {
    return await axios.post(`bank/remove-bank`);
};

const get_auth_check = async () => {
    return await axios.get(`users?user_type=4&per_page=1&page=1/`);
};
const get_set_user_details = async (user) => {
    localStorage.setItem("bearer", user.bearer);
    localStorage.setItem("user_id", user.current_user_id);
};

const getUpdateUserDetail = async (id, payload) => {
    return await axios.put(`users/${id}/`, payload);
};

const getUpdateUserName = async (id, payload) => {
    return await axios.put(`users/${id}/username`, payload);
};
const getUpdatedUser = async (id, payload) => {
    return await axios.put(`users/${id}`, payload);
};
const postNewUser = async (payload) => {
    return await axios.post(`users/`, payload);
};

const getRegister = async (payload) => {
    return await axios.post(`auth/register/`, payload);
};

const postChangePassword = async (payload) => {
    return await axios.post(`auth/change-password/`, payload);
};

const getForgotPass = async (payload) => {
    return await axios.post(`auth/forgot-password/`, payload);
};

const get_otp_success = async (payload) => {
    return await axios.post(`auth/verify-code/`, payload);
};

const get_set_new_pass = async (payload) => {
    return await axios.post(`auth/reset-password/`, payload);
};

const get_send_verify_email = async (payload) => {
    return await axios.post(`auth/send-verification-email/`, payload);
};
const get_verify_email = async (payload) => {
    return await axios.get(`auth/verify-email?token=${payload.token}/`);
};
const get_verify_email_code = async (payload) => {
    return await axios.get(`auth/verify-email?code=${payload.code}&&email=${payload.email}/`);
};

export {
    getLogin,
    getUserDetail,
    get_auth_check,
    get_set_user_details,
    getUpdateUserDetail,
    getUpdateUserName,
    getCardDetail,
    removeCardDetail,
    getBankDetail,
    removeBankDetail,
    postChangePassword,
    getForgotPass,
    getRegister,
    getLogout,
    get_otp_success,
    get_set_new_pass,
    get_send_verify_email,
    get_verify_email,
    get_verify_email_code,
    getUpdatedUser,
    postNewUser,
};
