import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Forms/Button";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import { getUserDetail } from "../../../Store/Reducers/Auth/services";
import {
    get_chats,
    send_message_to_fan,
    send_message_to_influencer,
    setChatLoading,
} from "../../../Store/Reducers/Dashboard/actions";
import { getDashboard, getUser } from "../../../Store/selectors";
import SingleMessage from "./SingleMessage";
import VoiceMsg from "./Voicemsg";
import Wrappper from "./Wrappper";
import Spinner from "../../../components/Spinner";
import { setLoading } from "../../../Store/Reducers/Dashboard/actions";
import { useRef } from "react";
import { SocketPath } from "../../../Store/Config/config";
import { io } from "socket.io-client";

export default function Messages() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, card_details } = useSelector(getUser);
    const { users, error, packages, chats, chat_loading } = useSelector(getDashboard);
    const [search, setSearch] = useState("");
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [all_chats, setAllChats] = useState(chats);
    const [selected_chat, setSelectedChat] = useState(null);
    const [selected_user, setSelectedUser] = useState(null);
    // const [contacts, setContacts] = useState([]);
    const [last_msg, setLastMsg] = useState(null);
    const [time, setTime] = useState(0);
    const [first, setFirst] = useState(true);
    const [is_record, set_is_record] = useState(false);
    const [socket, setSocket] = useState(null);

    const intervalRef = useRef();

    useEffect(() => {
        /* eslint-disable */
        if (selected_chat) {
            const newSocket = io(`${SocketPath}?chatId=${selected_chat}`, {
                transports: ['websocket']
            });

            setSocket(newSocket); // Store the socket instance in the state

            newSocket.on("connect", () => {
                console.log("Connected", selected_chat);
                newSocket.emit(`chat-${selected_chat}`);
            });

            newSocket.on(`chat-${selected_chat}`, (data) => {
                console.log("message arrived!");
                getChat();
            });

            newSocket.on("disconnect", () => {
                console.log(newSocket?.id); // Accessing the socket id from the stored instance
                console.log("Socket Disconnected!");
            });

            newSocket.on("connect_error", (error) => {
                console.log("Socket Error: " + error);
            });
        }
    }, [selected_chat]);
    useEffect(() => {
        let intervalId;
        if (last_msg && last_msg.responded === false) {
            intervalId = window.setInterval(() => {
                const now = new Date();
                const last_msg_date = new Date(last_msg.timestamp);
                const endDate = new Date(last_msg_date.getTime() + 15 * 60 * 60 * 1000);
                if (endDate > now.getTime()) {
                    const diff = new Date(endDate.getTime() - now.getTime());
                    const hours = Math.floor(diff / (1000 * 60 * 60));
                    // check if the maximum time has been reached
                    if (hours >= 15) {
                        window.clearInterval(intervalId);
                        return;
                    }
                    setTime(diff.getTime() - 1000);
                } else {
                }
            }, 1000);
        }
        return () => window.clearInterval(intervalId);
    }, [last_msg]);

    const formatTime = (time) => {
        const hours = Math.floor(time / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    };

    useEffect(() => {
        getChats();
        // intervalRef.current = window.setInterval(() => {
        // getChats();
        // }, 30000);
        // setTimeout(() => {
        //     ToastTopRight.fire({
        //         title: "Page is reloading in few seconds!",
        //         icon: "warning",
        //     });
        // }, 70000);
        // setTimeout(() => {
        //     window.location.reload("refresh");
        // }, 90000);
        // return () => window.clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        setAllChats([...chats]);
    }, [chats]);

    useEffect(() => {
        if (all_chats.length && first) {
            handleChatClick(all_chats[0]);
            setFirst(false);
        }
    }, [all_chats]);

    useEffect(() => {
        if (selected_chat) {
            getChat();
        }
    }, [selected_chat]);

    const getChats = () => {
        dispatch(get_chats());
    };

    const getChatName = (chat) => {
        if (user.role === "fan") {
            return chat.influencer.name;
        } else {
            return chat.fan.name;
        }
    };

    const getLastMessageObject = (messages) => {
        let lastMsg = messages[messages.length - 1];
        if (lastMsg) {
            return lastMsg;
            // both will see the timer now
            if (lastMsg.sender_id !== user.id) {
                return lastMsg;
            } else {
                return null;
            }
        }
    };

    const getLastMessage = (item) => {
        let lastMsg = item.messages[item.messages.length - 1];
        if (lastMsg) {
            if (lastMsg.type === "voice") {
                return "voice note";
            } else {
                return lastMsg.content;
            }
        }
    };

    const checkUnRepliedMessages = () => {
        const tempChats = all_chats.map((item) => {
            const last_msg = getLastMessageObject(item.messages);
            // if (last_msg && user.role !== "fan") {
            if (last_msg) {
                const now = new Date();
                const last_msg_date = new Date(last_msg.timestamp);
                const endDate = new Date(last_msg_date.getTime() + 15 * 60 * 60 * 1000);
                if (endDate > now.getTime()) {
                    item.unreplied = true;
                } else {
                    item.unreplied = false;
                }
            } else {
            }

            return item;
        });
        setAllChats([...tempChats]);
    };

    const filteredChats = useMemo(() => {
        if (search !== "") {
            return all_chats.filter((item) => getChatName(item).toLowerCase().match(search.toLowerCase()));
        } else {
            return all_chats;
        }
    }, [all_chats, search]);

    const handleChatClick = (chat) => {
        setSelectedChat(chat._id);
        setLastMsg(null);
        setTime(0);
        if (chats.filter((i) => i._id === chat._id).length) {
            const chatUser = chats.filter((i) => i._id === chat._id)[0];
            if (user.role === "fan") {
                getUserDetails(chatUser.influencer.id);
            } else {
                getUserDetails(chatUser.fan.id);
            }
        }
    };

    const getUserDetails = async (user_id) => {
        const res = await getUserDetail(user_id);
        setSelectedUser(res.data.user);
    };

    const handleSendMsg = (type, voice = null) => {
        dispatch(setChatLoading(true));
        let data = {
            message: {
                content: type === "message" ? message : voice,
                type: type === "message" ? "text" : "voice",
            },
        };
        let payload = {
            data: { ...data, chatId: selected_chat },
            success: () => {
                if (socket)
                    socket.send(true)
                ToastTopRight.fire({
                    icon: "success",
                    title: "Message Sent !",
                });
                dispatch(setChatLoading(false));
                getChat();
                setMessage("");
                setLastMsg(null);
                setTime(0);
            },
            error: (error) => {
                console.log(error);
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                dispatch(setChatLoading(false));
            },
        };
        dispatch(user.role === "fan" ? send_message_to_influencer(payload) : send_message_to_fan(payload));
    };

    const getChat = async () => {
        if (selected_chat) {
            try {
                const res = await axios.get("chats/" + selected_chat);
                setMessages(res.data.chat.messages);
                // if (user.role === "influencer") {
                setLastMsg(getLastMessageObject(res.data.chat.messages));
                checkUnRepliedMessages();
                // }
            } catch (e) {
                if (e.response?.data?.message) {
                    ToastTopRight.fire({
                        icon: "error",
                        title: e.response?.data?.message || "Something Went Wrong!",
                    });
                }
            }
        }
    };

    return (
        <Wrappper>
            <div className="main-div">
                <div id="wrapper p-0 ">
                    {chats.length ? (
                        <>
                            <div id="loadgrid" className="desktop">
                                <div className="tabs-left">
                                    <div id="title_starts" className="chat-title">
                                        <ul className="nav nav-tabs">
                                            <span className="search">
                                                <input
                                                    type="text"
                                                    value={search}
                                                    className="input_search"
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                            </span>
                                            {filteredChats.map((item) => {
                                                return (
                                                    <li
                                                        className={selected_chat === item._id ? "active" : ""}
                                                        onClick={() => handleChatClick(item)}
                                                    >
                                                        <a href="#">
                                                            <h3 className="name d-flex justify-content-between">
                                                                <span>{getChatName(item)}</span>
                                                                {item.unreplied && (
                                                                    <span>
                                                                        <GoPrimitiveDot />
                                                                    </span>
                                                                )}
                                                            </h3>
                                                            <h4 className="sub-msg">{getLastMessage(item)}</h4>
                                                            {/* <h4 className="min">1 min</h4> */}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    {selected_user && (
                                        <div className="tab-content chat-des ">
                                            <div id="conversaion_starts" className="tab-pane active">
                                                <span className="title">
                                                    <h3>{selected_user?.name || ""}</h3>
                                                    {time ? (
                                                        <span className="time-expire">- {formatTime(time)}</span>
                                                    ) : null}
                                                </span>
                                                <div className="message-info">
                                                    {messages.map((msg) => {
                                                        return <SingleMessage user={user} msg={msg} />;
                                                    })}

                                                    <div className="reply">
                                                        <div className="attach" onClick={() => setMessage("")}>
                                                            <VoiceMsg
                                                                send={handleSendMsg}
                                                                user={user}
                                                                set_is_record={set_is_record}
                                                            />
                                                        </div>
                                                        <div className="reply-area">
                                                            <textarea
                                                                value={message}
                                                                className="form-control"
                                                                placeholder="Type your message...."
                                                                onChange={(e) => !chat_loading && setMessage(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className="reply-submit">
                                                            {chat_loading ? (
                                                                <Spinner width={"20px"} height={"20px"} />
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    style={{
                                                                        opacity: is_record ? 0.5 : 1,
                                                                    }}
                                                                    className="btn btn-default simple-btn text-center"
                                                                    onClick={() =>
                                                                        !is_record ? handleSendMsg("message") : {}
                                                                    }
                                                                >
                                                                    <img src="https://akshaysyal.files.wordpress.com/2017/03/send_icon.png" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div id="loadgrid" className="mobile">
                                <div className="tabs-left">
                                    {!selected_user && (
                                        <div id="title_starts" className="chat-title">
                                            <ul className="nav nav-tabs">
                                                <span className="search">
                                                    <input type="text" value="Search" className="input_search" />
                                                </span>
                                                {chats.map((item) => {
                                                    return (
                                                        <li
                                                            className={selected_chat === item._id ? "active" : ""}
                                                            onClick={() => handleChatClick(item)}
                                                        >
                                                            <a href="#">
                                                                <h3 className="name">{getChatName(item)}</h3>
                                                                <h4 className="sub-msg">{getLastMessage(item)}</h4>
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    {selected_user && (
                                        <div className="tab-content chat-des ">
                                            <div id="conversaion_starts" className="tab-pane active">
                                                <span className="title">
                                                    <h3>
                                                        {" "}
                                                        <BiArrowBack onClick={() => setSelectedUser(null)} />{" "}
                                                        {selected_user?.name || ""}
                                                    </h3>
                                                    {time ? (
                                                        <span className="time-expire mobile">- {formatTime(time)}</span>
                                                    ) : null}
                                                </span>
                                                <div className="message-info">
                                                    {messages.map((msg) => {
                                                        return <SingleMessage user={user} msg={msg} />;
                                                    })}
                                                </div>
                                                <div className="reply">
                                                    <div className="attach" onClick={() => setMessage("")}>
                                                        <VoiceMsg
                                                            send={handleSendMsg}
                                                            user={user}
                                                            set_is_record={set_is_record}
                                                        />
                                                    </div>
                                                    <div className="reply-area">
                                                        <textarea
                                                            value={message}
                                                            className="form-control"
                                                            placeholder="Type your message...."
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        ></textarea>
                                                    </div>
                                                    <div className="reply-submit">
                                                        {chat_loading ? (
                                                            <Spinner width={"20px"} height={"20px"} />
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    opacity: is_record ? 0.5 : 1,
                                                                }}
                                                                className="btn btn-default simple-btn text-center"
                                                                onClick={() =>
                                                                    !is_record ? handleSendMsg("message") : {}
                                                                }
                                                            >
                                                                <img src="https://akshaysyal.files.wordpress.com/2017/03/send_icon.png" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {chat_loading ? (
                                <div className="d-flex justify-content-center m-5">
                                    <Spinner width={"80px"} height={"80px"} />
                                </div>
                            ) : (
                                <>
                                    <div className="jumbotron text-center my-5">
                                        <h1 className="display-4">Sorry!</h1>
                                        <p className="lead">No Chat Found!</p>
                                        <hr className="my-4" />
                                        {user.role === "fan" && (
                                            <Button
                                                className={"btn btn-outline-light  btn-submit m-3"}
                                                type="submit"
                                                link={"/dashboard"}
                                            >
                                                <CiSearch
                                                    style={{
                                                        fontSize: "24px",
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Find Influencers
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Wrappper>
    );
}
