import React, {useEffect} from 'react'
import TopBar from '../../components/dashboard/TopBar'
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {get_packages} from '../../Store/Reducers/Dashboard/actions'
import {getDashboard, getUser} from '../../Store/selectors'
import {get_card_details} from '../../Store/Reducers/Auth/actions'
import Users from './Users'
import Earnings from './Earnings'
import ChangePassword from '../dashboard/ChangePassword'

function Dashboard(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {packages} = useSelector(getDashboard)
    const {user} = useSelector(getUser)

    useEffect(() => {
        dispatch(get_packages())
        dispatch(get_card_details())
    }, [])
    return (
        <div>
            <div className='container-fluid '>
                <div className='row '>
                    <div className='col-md-12 p-0 m-0'>
                        <TopBar />
                    </div>
                    <div className='col-md-12 p-0'>
                        <Routes>
                            <Route index element={<Users />} />
                            {/* <Route path="voicemsg" element={<VoiceMsg />} /> */}

                            <Route path='earnings' element={<Earnings />} />
                            <Route
                                path='change-password'
                                element={<ChangePassword />}
                            />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
