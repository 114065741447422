import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Forms/Button";
import Input from "../../components/Forms/Input";
import Spinner from "../../components/Spinner";
import LogoImg from "../../assets/images/Frame 16.png";

import { ToastTopRight } from "../../components/Toasts/top-right";
import {
    get_bank_details,
    get_card_details,
    login,
    setLoading
} from "../../Store/Reducers/Auth/actions";
import { getUser } from "../../Store/selectors";
import Wrapper from "./Signup.styled";

function Login(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, loading } = useSelector(getUser);
    const [screen, setScreen] = useState(5);
    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const [errField, setErrField] = useState({
        email: "",
        password: ""
    });
    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setData({ ...data, [name]: value });
    };
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    // validation
    const clearErrors = () => {
        errField.name = "";
        errField.email = "";
        errField.password = "";
        setErrField(errField);
    };
    const checkValidation = () => {
        clearErrors();

        var error = false;
        const errForm = { email: "", password: "" };
        /* eslint-disable */
        Object.keys(data).map(function (keyName, keyIndex) {
            const value = data[keyName];

            if (keyName === "email") {
                if (value === "") {
                    errForm.email = "ⓘ Email Address Is Required!";
                    error = true;
                } else if (!validateEmail(value)) {
                    errForm.email = "ⓘ Invalid Email Address!";
                    error = true;
                }
            }

            if (keyName === "password") {
                if (value === "") {
                    errForm.password = "ⓘ Password Is Required!";
                    error = true;
                } else if (value.length > 16 || value.length < 8) {
                    errForm.password =
                        "ⓘ Password Should Be Between 8 And 16 Characters!";
                    error = true;
                }
            }

            setErrField(errForm);
        });
        return error;
    };

    const handleSubmit = async () => {
        dispatch(setLoading(true));
        if (checkValidation()) {
            dispatch(setLoading(false));
            return;
        }

        let payload = {
            ...data,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "LoggedIn Successfully!"
                });
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
                dispatch(setLoading(false));
                dispatch(get_card_details());
                dispatch(get_bank_details());
            },
            failure: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg
                });
                dispatch(setLoading(false));
            }
        };
        dispatch(login(payload));
    };

    return (
        <Wrapper>
            <div className="container-fluid mt-5">
                <div className="centering">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <div className="get-start-text mt-5">
                                        <div className="logo">
                                            {/* <img src={LogoImg} alt={"logoImg"} /> */}
                                        </div>
                                        <h6>Login</h6>
                                        <p className="mt-12p">Welcome Back!</p>
                                    </div>
                                </div>
                            </div>
                            <center>
                                <div className="flex-column justify-content-center ">
                                    {/* <div className="col-lg-4 mt-3">
                    <Input type={"email"} placeholder="Enter email address" />
                  </div> */}
                                    <div className="col-lg-4 mt-3">
                                        <Input
                                            className={`form-control ${
                                                errField.email !== ""
                                                    ? "error-field is-invalid"
                                                    : ""
                                            }`}
                                            type={"email"}
                                            placeholder="Enter email address"
                                            onChange={handleInput}
                                            name="email"
                                        />
                                        {errField.email !== "" ? (
                                            <div
                                                className="errors"
                                                style={{ textAlign: "left" }}
                                            >
                                                <span className="form-text text-danger">
                                                    {errField.email}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* <div className="col-lg-4 mt-3">
                    <Input type={"password"} placeholder="Enter password" />
                  </div> */}
                                    <div className="col-lg-4 mt-3">
                                        <Input
                                            name="password"
                                            type={"password"}
                                            className={`form-control ${
                                                errField.password !== ""
                                                    ? "error-field is-invalid"
                                                    : ""
                                            }`}
                                            placeholder="Enter password"
                                            onChange={handleInput}
                                        />
                                        {errField.password !== "" ? (
                                            <div
                                                className="errors"
                                                style={{ textAlign: "left" }}
                                            >
                                                <span className="form-text text-danger">
                                                    {errField.password}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center mt-5">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <>
                                            <Button
                                                className={
                                                    "btn btn-primaryy w-100"
                                                }
                                                onClick={() => handleSubmit()}
                                            >
                                                Submit
                                            </Button>
                                            <div className="account mt-5">
                                                <Link
                                                    to="/signup"
                                                    className="btn gotoLogin"
                                                >
                                                    <span>
                                                        <BiArrowBack
                                                            fontSize={"20px"}
                                                        />
                                                    </span>
                                                    &nbsp; Need new account?
                                                    Signup
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Login;
