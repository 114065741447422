import styled from "styled-components";

const Wrappper = styled.div`
  .container-fluid {
    width: 100%;
    height: 86.6vh;
    display: flex;
    justify-content: center;
    background-color: #f7f7f7;
  }

  .iphone {
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .header {
    min-height: 30%;
    background-color: #046d68 !important;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin: 10px 0px;
    padding: 0px 150px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .header::before {
    content: "";
    height: 200px;
    width: 200px;
    background-color: #53b5a8;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    left: -100px;
    z-index: -1;
  }

  .header::after {
    content: "";
    height: 100px;
    width: 100px;
    background-color: #53b5a8;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    bottom: -30px;
    z-index: -1;
  }

  .header-summary {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    color: white;
    z-index: 3;
  }

  .summary-text {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 1.5rem;
  }

  .summary-balance {
    font-size: 2rem;
    letter-spacing: 1.5px;
    margin-bottom: 0.8rem;
  }

  .summary-text-2 {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  .user-profile {
    /* margin-top: 4rem; */
    height: 70px;
    width: 70px;
    /* align-self: flex-start; */
  }

  .user-photo {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }

  .cards {
    margin: 20px;
    text-align: center;
  }

  #type4 {
    background: #78909c;
    padding-bottom: 50px;
    width: 100%;
  }
  .btn-4 {
    border-radius: 12px;
    color: var(--white);
    background-color: #046d68 !important;

    text-align: center;
    display: inline-block;
    padding: 15px 130px;
    border: 1px solid #fff;
    cursor: pointer;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    margin: 0 20px;
  }

  .btn-4:before {
    content: "";
    position: absolute;
    height: 150px;
    width: 50px;
    background: #fff;
    right: -40px;
    top: -40px;
    transform: rotate(37deg);
    transition: all 0.3s;
    opacity: 0.3;
  }

  .btn-4:hover:before {
    right: 105%;
  }
  @media screen and (max-width: 967px) and (min-width: 237px) {
    .header {
      padding: 0px 90px !important;
    }
    .btn-4 {
      margin: 0px 0px;
      padding: 15px 90px;
    }
  }
  @media screen and (max-width: 410px) and (min-width: 220px) {
    .header {
      padding: 0px 40px !important;
      .summary-balance {
        font-size: 1.5rem;
      }
    }
    .summary-text {
      font-size: 16px;
    }
    .btn-4 {
      margin: 0px 0px;
      padding: 15px 90px;
    }
  }
`;
export default Wrappper;
