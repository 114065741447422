import React, { useEffect, useState } from "react";
import { Modal, Input } from "antd";
import styled from "styled-components";
import Button from "../Forms/Button";
import { ToastTopRight } from "../Toasts/top-right";
import { getDashboard, getUser } from "../../Store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../Store/Reducers/Auth/actions";
import { create_chat, get_chats, send_message_to_influencer } from "../../Store/Reducers/Dashboard/actions";
import Spinner from "../Spinner";
import VoiceMsg from "../../pages/dashboard/Messages/Voicemsg";
import CardDetailModal from "../modals/CardDetailModal";
const { TextArea } = Input;

export default function MessageBox({
    isModalOpen,
    handleCancel,
    influencer,
    // packages,
    pkgPurchase,
    dont,
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, user, card_details, token } = useSelector(getUser);
    const { users, error, packages, chats } = useSelector(getDashboard);
    const [message, setMessage] = useState("");
    const [chat_exists, setChatExists] = useState(false);
    const [card_modal, setCardModal] = useState(false);
    const [is_record, set_is_record] = useState(false);

    const onChange = (e) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        if (!dont) {
            dispatch(get_chats());
        }
    }, [influencer.id]);

    useEffect(() => {
        setChatExists(chats.filter((i) => i.influencer.id === influencer.id).length > 0 ? true : false);
    }, [chats]);

    const sendMessage = (type, voice = null) => {
        if (!user?.id || user?.id === "" || token === "") {
            ToastTopRight.fire({
                icon: "error",
                title: "Login first to message !",
            });
            setTimeout(() => {
                navigate("/");
            }, 1000);
            return;
        }
        if (user.role !== "fan") {
            ToastTopRight.fire({
                icon: "error",
                title: "Only Fan can buy!",
            });
            return;
        }
        if (!card_details?.id && user?.credits < 4) {
            ToastTopRight.fire({
                icon: "error",
                title: "Please Add Your Card Details First!",
            });

            setCardModal(true);
            // setTimeout(() => {
            //     navigate("/dashboard/" + user.name);
            // }, 1000);
            return;
        }
        dispatch(setLoading(true));
        let data = {
            message: {
                content: type === "message" ? message : voice,
                type: type === "message" ? "text" : "voice",
            },
        };
        if (chat_exists) {
        } else {
            data.influencerId = influencer.id;
        }
        // console.log(data, "data");
        let payload = {
            data: data,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Message Sent !",
                });
                handleCancel();
                dispatch(setLoading(false));
                setTimeout(() => {
                    navigate("/dashboard/messages");
                }, 1000);
            },
            error: (error) => {
                console.log(error, "error fall");
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                dispatch(setLoading(false));
                // setTimeout(() => {
                //     navigate("/dashboard/wallet");
                // }, 1000);
            },
        };

        if (chat_exists) {
            ToastTopRight.fire({
                icon: "success",
                title: "Chat Already Exists!",
            });
            setTimeout(() => {
                navigate("/dashboard/messages");
            }, 1000);
        } else {
            dispatch(create_chat(payload));
        }
    };
    const purchase = (item) => {
        pkgPurchase({ packageId: item.id });
    };
    return (
        <>
            <CardDetailModal modal={card_modal} setModal={setCardModal} />

            <Modal title={`Send message`} open={isModalOpen} onCancel={handleCancel} footer={false} width={900}>
                <Wrappper>
                    <div className="container text-center">
                        <div className="row d-flex align-items-center p-0">
                            <div className="col-md-4 profile_div  p-0">
                                <span className="">@{influencer.username}</span>
                                <img alt="profile_pic" src={influencer.profile_pic} />
                            </div>
                            {user?.credits < 4 || !user?.id ? (
                                <div
                                    class="input_div col-md-6 d-flex  align-items-center justify-content-around p-0"
                                    method="post"
                                >
                                    {loading ? (
                                        <div className="d-flex justify-content-center m-3">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <>
                                            {packages.map((item, index) => {
                                                return (
                                                    <div class=" ">
                                                        <Button
                                                            className={"btn btn-outline-light  btn-submit"}
                                                            onClick={() => {
                                                                purchase(item);
                                                            }}
                                                        >
                                                            {item.credits > 4 ? "10 Messages $40" : "1 Message $4"}
                                                        </Button>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div
                                    class="input_div col-md-6 d-flex flex-column align-items-center justify-content-around p-0"
                                    method="post"
                                >
                                    <>
                                        <TextArea
                                            className="m-1"
                                            size="large"
                                            rows={4}
                                            placeholder="type your message"
                                            type="text"
                                            allowClear
                                            onChange={onChange}
                                        />

                                        {loading ? (
                                            <div className="d-flex justify-content-center m-3">
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-around  m-3">
                                                <Button
                                                    className={"btn btn-outline-light  btn-submit"}
                                                    style={{
                                                        opacity: is_record ? 0.5 : 1,
                                                    }}
                                                    type="submit"
                                                    onClick={() => (!is_record ? sendMessage("message") : {})}
                                                >
                                                    Send for 4$
                                                </Button>

                                                <div className="mt-1">
                                                    <button
                                                        style={{
                                                            background: "none",
                                                            border: "1px solid grey",
                                                            marginLeft: "10px",
                                                            padding: "7px",
                                                        }}
                                                        onClick={() => setMessage("")}
                                                    >
                                                        <VoiceMsg
                                                            send={sendMessage}
                                                            user={user}
                                                            set_is_record={set_is_record}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </div>
                            )}
                        </div>
                    </div>
                </Wrappper>
            </Modal>
        </>
    );
}

const Wrappper = styled.div`
    background: #cccccc !important;
    padding: 0px 0px 40px 0px;
    span {
        font-style: italic;
        font-weight: 600;
        color: var(--color-light);
        font-size: 24px;
    }
    .input_div {
        margin-top: 100px;
    }
    .profile_div {
        margin: 25px 10px;
        width: 25rem;
        height: 20rem;
        img {
            width: 100%;
            height: 100%;
            border: 10px solid #fffefd;
        }
    }
    .btn-submit {
        padding: 10px 40px;
        font-size: 16px;
    }

    .profile_pic {
        width: 100%;
        border-radius: 10px;
    }
`;
