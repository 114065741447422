import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Input from "../../../components/Forms/Input";
import { CiSearch } from "react-icons/ci";
import { Data } from "./Data.js";
import Button from "../../../components/Forms/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard, getUser } from "../../../Store/selectors";
import { get_users, purchase_packages } from "../../../Store/Reducers/Dashboard/actions";
import MessageBox from "../../../components/MessageBox/Index";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import { setLoading } from "../../../Store/Reducers/Auth/actions";
import Packages from "../Packages/Index";
import Spinner from "../../../components/Spinner";
import CardDetailModal from "../../../components/modals/CardDetailModal";
function Influencers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, card_details } = useSelector(getUser);
    const { loading, users, error, packages } = useSelector(getDashboard);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [dollar, setDollar] = useState(4);
    const [influencer, setInfluencer] = useState("");
    const [search, setSearch] = useState("");
    const [card_modal, setCardModal] = useState(false);

    useEffect(() => {
        if (user.role === "influencer") {
            navigate("/dashboard/messages");
        }
        dispatch(get_users({ type: "influencer" }));
        dispatch(setLoading(false));
    }, []);

    const filteredUsers = () => {
        if (search !== "") {
            return users.filter((item) => item.name?.toLowerCase().match(search.toLowerCase()));
        } else {
            return users;
        }
    };

    const pkgPurchase = (pkg) => {
        if (!card_details?.id) {
            ToastTopRight.fire({
                icon: "error",
                title: "Please Add Card Details First!",
            });
            setCardModal(true);
            // navigate(user.name);
            return;
        }
        let payload = {
            data: pkg,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Purchased Successfully !",
                });
                setIsModalOpen(true);
                setIsModalVisible(false);
                dispatch(setLoading(false));
            },
            error: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                navigate(user.username);
                dispatch(setLoading(false));
            },
        };
        dispatch(purchase_packages(payload));
    };
    //choose pkg
    const ModalVisible = (influencer) => {
        // if (user.credits) {
        setInfluencer(influencer);
        showModal();
        // } else {
        //   setIsModalVisible(true);
        // }
    };

    const handleCcancel = () => {
        setIsModalVisible(false);
    };

    //send msg
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <Wrapper>
            <CardDetailModal modal={card_modal} setModal={setCardModal} />
            {user.role === "fan" && !loading ? (
                <>
                    <div className="container-fluid mt-5 ">
                        <div class="row">
                            <div class="col-md-2">
                                <p>Influencers:</p>
                            </div>
                            <div class="col-md-9">
                                <Input
                                    rightImgWidth={"30px"}
                                    firsticon={CiSearch}
                                    placeholder={"find your celebrity"}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>
                    <div class="container-fluid mt-3 mb-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="user-dashboard-info-box table-responsive mb-0 bg-white p-4 shadow-sm">
                                    <table class="table manage-candidates-top mb-0">
                                        <tbody>
                                            {filteredUsers().length < 1 && (
                                                <tr class="candidates-list">
                                                    <td colSpan={10}>No Influencers Found!</td>
                                                </tr>
                                            )}
                                            {filteredUsers().map((item, ind) => (
                                                <tr class="candidates-list">
                                                    <td class="title">
                                                        <div class="thumb">
                                                            <img class="img-fluid" src={item.profile_pic} alt="" />
                                                        </div>
                                                        <div class="candidate-list-details">
                                                            <div class="candidate-list-info">
                                                                <div class="candidate-list-title">
                                                                    <h5 class="mb-0">@{item.username}</h5>
                                                                </div>
                                                                <div class="candidate-list-option">
                                                                    <ul class="list-unstyled">
                                                                        <li>
                                                                            <i class="fas fa-filter pr-1"></i>
                                                                            {item.bio}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="candidate-list-favourite-time text-center">
                                                        <span class="candidate-list-time order-1">
                                                            <Button
                                                                className={"submit"}
                                                                onClick={() => ModalVisible(item)}
                                                            >
                                                                {user.credits ? "Send Message" : "Buy Message"}
                                                            </Button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-center mt-5">
                        <Spinner />
                    </div>
                </>
            )}

            <MessageBox
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                influencer={influencer}
                packages={packages}
                pkgPurchase={pkgPurchase}
            />
            {/* <Packages
        handleCcancel={handleCcancel}
        isModalVisible={isModalVisible}
        packages={packages}
        pkgPurchase={pkgPurchase}
        //
      /> */}
        </Wrapper>
    );
}

export default Influencers;

const Wrapper = styled.div`
    p {
        align-items: center;
        text-align: center;
        color: var(--color-light);
        font-weight: 500;
        font-size: 24px;
    }

    .p-4 {
        padding: 1.5rem !important;
    }
    .mb-0,
    .my-0 {
        margin-bottom: 0 !important;
    }
    .shadow-sm {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
    /* user-dashboard-info-box */
    .user-dashboard-info-box .candidates-list .thumb {
        margin-right: 20px;
    }
    .user-dashboard-info-box .candidates-list .thumb img {
        width: 150px;
        height: 150px;
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
    }

    .user-dashboard-info-box .title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 30px 0;
    }

    .user-dashboard-info-box .candidates-list td {
        vertical-align: middle;
    }

    .user-dashboard-info-box td li {
        margin: 0 4px;
    }

    .user-dashboard-info-box .table thead th {
        border-bottom: none;
    }

    .table.manage-candidates-top th {
        border: 0;
    }

    .user-dashboard-info-box .candidate-list-favourite-time .candidate-list-favourite {
        margin-bottom: 10px;
    }

    .table.manage-candidates-top {
        /* min-width: 650px; */
    }

    .user-dashboard-info-box .candidate-list-details ul {
        /* color: #969696; */
        color: var(--color-light);
    }

    /* Candidate List */
    .candidate-list {
        background: #ffffff;
        display: none;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #eeeeee;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 20px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .candidate-list:hover {
        -webkit-box-shadow: 0px 0px 34px 4px rgba(33, 37, 41, 0.06);
        box-shadow: 0px 0px 34px 4px rgba(33, 37, 41, 0.06);
        position: relative;
        z-index: 99;
    }
    .candidate-list:hover a.candidate-list-favourite {
        color: #e74c3c;
        -webkit-box-shadow: -1px 4px 10px 1px rgba(24, 111, 201, 0.1);
        box-shadow: -1px 4px 10px 1px rgba(24, 111, 201, 0.1);
    }

    .candidate-list .candidate-list-image {
        margin-right: 25px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 80px;
        flex: 0 0 80px;
        border: none;
    }
    .candidate-list .candidate-list-image img {
        width: 150px;
        height: 150px;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .candidate-list-title {
        margin-bottom: 5px;
        h5,
        a {
            color: #47b0a1 !important;
        }
    }
    .submit {
        padding: 10px 40px;
    }
    .candidate-list-details ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 0px;
    }
    .candidate-list-details ul li {
        margin: 5px 10px 5px 0px;
        font-size: 13px;
    }

    .candidate-list .candidate-list-favourite-time {
        margin-left: auto;
        text-align: center;
        font-size: 13px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px;
    }
    .candidate-list .candidate-list-favourite-time span {
        display: block;
        margin: 0 auto;
    }
    .candidate-list .candidate-list-favourite-time .candidate-list-favourite {
        display: inline-block;
        position: relative;
        height: 40px;
        width: 40px;
        line-height: 40px;
        border: 1px solid #eeeeee;
        border-radius: 100%;
        text-align: center;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        margin-bottom: 20px;
        font-size: 16px;
        color: #646f79;
    }
    .candidate-list .candidate-list-favourite-time .candidate-list-favourite:hover {
        background: #ffffff;
        color: #e74c3c;
    }

    .candidate-banner .candidate-list:hover {
        position: inherit;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        z-index: inherit;
    }

    .bg-white {
        background-color: #ffffff !important;
    }
    .p-4 {
        padding: 1.5rem !important;
    }
    .mb-0,
    .my-0 {
        margin-bottom: 0 !important;
    }
    .shadow-sm {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .user-dashboard-info-box .candidates-list .thumb {
        margin-right: 20px;
    }
    .page-item.active .page-link {
        background-color: #046d68 !important;
    }

    @media screen and (max-width: 967px) {
        .candidates-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;

            .title {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            .submit {
                padding: 10px 10px;
            }
        }
    }
`;
