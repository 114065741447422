import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { axios, basePath, getBearerToken } from "../../../Store/Config/config";
import "./users.css";
import UserForm from "./UserForm";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import DataTable from "react-data-table-component";
import { getUpdatedUser, postNewUser } from "../../../Store/Reducers/Auth/services";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(1000000);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [userSearch, setUserSearch] = useState("");

    // useEffect(() => {
    //     fetchUsers();
    // }, [currentPage]);
    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async (params) => {
        const { name } = params || {};
        try {
            const response = await fetch(
                `${basePath}users?limit=${usersPerPage}&page=${currentPage}${name ? `&name=${name}` : ""}`,
            );
            const data = await response.json();
            setUsers(data.results);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.log("Error fetching users:", error);
        }
    };

    const openModal = async (userId) => {
        try {
            const response = await fetch(`${basePath}users/${userId}`);
            const data = await response.json();
            setSelectedUser(data.user);
            setModalOpen(true);
        } catch (error) {
            console.log("Error fetching user details:", error);
        }
    };

    const closeModal = () => {
        setSelectedUser(null);
        setModalOpen(false);
    };

    const deleteUser = async (userId) => {
        const confirm = window.confirm("Are you sure you want to delete this user?");
        if (confirm) {
            try {
                const response = await fetch(`${basePath}users/${userId}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${await getBearerToken()}`,
                    },
                });
                const data = await response.json();
                if (response?.status === 200) {
                    ToastTopRight.fire({
                        icon: "success",
                        title: `${data?.message}`,
                    });
                    fetchUsers();
                }
            } catch (error) {
                console.log("Error fetching user details:", error);
                ToastTopRight.fire({
                    icon: "error",
                    title: `${error?.message}`,
                });
            }
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const filteredUsers = () => {
        let results = users;
        if (userSearch.length) {
            return results.filter((item) => {
                if (item.name?.toLowerCase().match(userSearch.toLowerCase())) {
                    return item;
                }
            });
        } else {
            return results;
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (userSearch.length > 2) {
            try {
                setCurrentPage(() => {
                    return 1;
                });
                await fetchUsers({ name: userSearch });
                // setUserSearch('')
            } catch (err) {
                console.error(err);
            }
        }
    };

    const handleOnSubmit = async (dataToPost, editingState) => {
        try {
            let response = null;
            if (editingState) {
                delete dataToPost.password;
                delete dataToPost.email;
                response = await getUpdatedUser(selectedUser.id, dataToPost);
            } else {
                response = await postNewUser(dataToPost);
            }
            // if (response?.status === 201) {
            //     await fetchUsers()
            // }
            if (response?.status === 200 || response?.status === 201) {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Success !",
                });
                await fetchUsers();
            } else {
                ToastTopRight.fire({
                    icon: "error",
                    title: `There was an error in the query. Please try again`,
                });
            }
            setSelectedUser(null);
            setModalOpen(false);
        } catch (err) {
            ToastTopRight.fire({
                icon: "error",
                title: `${err?.message}`,
            });
            console.error(err);
        }
    };

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Profile picture",
            selector: (row) => {
                return (
                    <>
                        {row?.profile_pic ? (
                            <img
                                src={row.profile_pic}
                                alt="Profile img"
                                width={100}
                                height={100}
                                className="admin-user-img"
                                style={{ bordeRadius: "5px" }}
                            />
                        ) : (
                            "N/A"
                        )}
                    </>
                );
            },
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name || "N/A",
            sortable: true,
        },

        {
            name: "Email",
            selector: (row) => row.email || "N/A",
            sortable: true,
        },
        {
            name: "Role",
            selector: (row) => row.role || "N/A",
            sortable: true,
        },

        {
            name: "Action",
            selector: (row) => {
                return (
                    <>
                        <button
                            className="btn btn-info btn-sm mx-2"
                            onClick={() => openModal(row.id)}
                            style={{ cursor: "pointer" }}
                        >
                            Edit
                        </button>
                        {row?.deleted === true ? (
                            <span className="text-danger">Deleted!</span>
                        ) : (
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => deleteUser(row.id)}
                                style={{ cursor: "pointer" }}
                            >
                                Delete
                            </button>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className="users-container pt-2 px-4 bg-white">
            <h3 className="text-success text-center mb-3">All Users</h3>

            <div className="">
                <form class="form-inline" onSubmit={handleSearch}>
                    <div class="row">
                        <div class="col-6">
                            <input
                                type="text"
                                class="form-control mb-2 mr-sm-2 w-100"
                                placeholder='Type here e.g "John Doe"'
                                id="user-search"
                                name="user-search"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value)}
                            />
                        </div>
                        {/* <div class="col-2 d-flex align-items-center justify-content-start mt-3">
                            <button type="submit" class="btn btn-primary">
                                Search
                            </button>
                        </div> */}
                        <div class="col-6 d-flex align-items-center justify-content-end">
                            <button
                                type="button"
                                class="btn btn-success"
                                onClick={() => {
                                    setSelectedUser(null);
                                    setModalOpen(true);
                                }}
                            >
                                Add A User
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {users && users.length < 1 && (
                <div class="text-center">
                    {" "}
                    <h2>No record found</h2>
                </div>
            )}
            {users && users.length > 0 && (
                <DataTable selectableRows columns={columns} data={filteredUsers()} pagination />
            )}

            {/* <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Profile picture</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => (
                            <tr key={user.id}>
                                <th scope="row">{1 + index}</th>
                                <td>
                                    {user?.profile_pic ? (
                                        <img
                                            src={user.profile_pic}
                                            alt="Profile img"
                                            width={100}
                                            height={100}
                                            className="admin-user-img"
                                            style={{ bordeRadius: "5px" }}
                                        />
                                    ) : (
                                        "N/A"
                                    )}
                                </td>
                                <td>{user?.name || "N/A"}</td>
                                <td>{user?.email || "N/A"}</td>
                                <td>{user?.role || "N/A"}</td>
                                <td
                                    onClick={() => openModal(user.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <button className="btn btn-info btn-sm">
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}

            {/* <div className='user-list'>
                {users.map(user => (
                    <div key={user.id} className='user-item'>
                        <p>{user.name}</p>
                        <p>{user.email}</p>
                        <p>{user.role}</p>
                        <p>{user.phone}</p>
                        <img
                            src={user.profile_pic}
                            alt='Profile Picture'
                            width={100}
                            height={100}
                            className='admin-user-img'
                        />

                        <button onClick={() => openModal(user.id)}>Edit</button>

                        <hr />
                    </div>
                ))}
            </div> */}

            {/* {totalPages > 1 && (
                <div>
                    {Array.from(
                        { length: totalPages },
                        (_, index) => index + 1,
                    ).map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            )} */}

            {modalOpen && (
                <Modal
                    visible={modalOpen}
                    className=""
                    footer={false}
                    title={selectedUser ? "Edit user" : "Add user"}
                    onCancel={closeModal}
                >
                    <UserForm
                        data={selectedUser ? selectedUser : null}
                        onSubmit={handleOnSubmit}
                        isEditing={selectedUser ? true : false}
                    />
                </Modal>
            )}
            {/* {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <button onClick={closeModal}>Close</button>

                        {selectedUser && (
                            <div>
                                <h2>User Details</h2>
                                <p>Name: {selectedUser.name}</p>
                                <p>Email: {selectedUser.email}</p>
                                <p>Role: {selectedUser.role}</p>
                                <p>Phone: {selectedUser.phone}</p>
                                <img
                                    src={selectedUser.profile_pic}
                                    alt='Profile Picture'
                                />
                            </div>
                        )}
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default Users;
