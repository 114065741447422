import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { basePath, getBearerToken } from "../../../Store/Config/config";
import "./earnings.css";
import DataTable from "react-data-table-component";

const Index = () => {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState("all");
    const [userSearch, setUserSearch] = useState("");

    useEffect(() => {
        fetchUsers();
    }, [filter]);

    const fetchUsers = async () => {
        try {
            const response = await axios.post(
                `${basePath}users/earnings`,
                { role: filter },
                {
                    headers: {
                        Authorization: `Bearer ${await getBearerToken()}`,
                    },
                },
            );
            setUsers(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const filteredUsers = () => {
        let results = users;
        if (userSearch.length) {
            return results.filter((item) => {
                if (
                    item.name?.toLowerCase().match(userSearch.toLowerCase()) ||
                    item.username?.toLowerCase().match(userSearch.toLowerCase()) ||
                    item.email?.toLowerCase().match(userSearch.toLowerCase()) ||
                    item._id?.toLowerCase().match(userSearch.toLowerCase())
                ) {
                    return item;
                }
            });
        } else {
            return results;
        }
    };

    const columns = [
        {
            name: "#",
            selector: (row) => row._id,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.role,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name || "N/A",
            sortable: true,
        },
        {
            name: "Username",
            selector: (row) => row.username || "N/A",
            sortable: true,
        },

        {
            name: "Email",
            selector: (row) => row.email || "N/A",
            sortable: true,
        },

        {
            name: "Created At",
            selector: (row) => {
                return <>{new Date(row.createdAt)?.toUTCString()}</>;
            },
            sortable: true,
        },
        {
            name: "$Balance",
            selector: (row) => `$${row.balance}`,
            sortable: true,
        },
        {
            name: "$Earnings Transferred",
            selector: (row) => `$${row.earningsTransferred}`,
            sortable: true,
        },
        {
            name: "$Credits",
            selector: (row) => `$${row.credits}`,
            sortable: true,
        },
    ];

    return (
        <div className="users-container pt-2 px-4 bg-white">
            <h3 className="text-success text-center mb-3">Earnings</h3>
            {/* <div className="text-center mb-4">
                <h4>Total Platform Earning: {loadingPlatfoprmEarning ? "Loading..." : `$${platformEarning}`}</h4>
            </div> */}
            <div>
                <div className="text-center mb-3">
                    <button
                        className={`filter-button ${filter === "all" ? "active" : ""}`}
                        onClick={() => handleFilterChange("all")}
                    >
                        All
                    </button>{" "}
                    <button
                        className={`filter-button ${filter === "fan" ? "active" : ""}`}
                        onClick={() => handleFilterChange("fan")}
                    >
                        Fans
                    </button>{" "}
                    <button
                        className={`filter-button ${filter === "influencer" ? "active" : ""}`}
                        onClick={() => handleFilterChange("influencer")}
                    >
                        Influencers
                    </button>
                </div>
                <form class="form-inline">
                    <div class="row">
                        <div class="col-6">
                            <input
                                type="text"
                                class="form-control mb-2 mr-sm-2 w-100"
                                placeholder='Search from "Name", "Email", "Username" or "Id"'
                                id="user-search"
                                name="user-search"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value)}
                            />
                        </div>
                        {/* <div class="col-2 d-flex align-items-center justify-content-start mt-3">
                            <button type="submit" class="btn btn-primary">
                                Search
                            </button>
                        </div> */}
                    </div>
                </form>
                {users && users.length < 1 && (
                    <div class="text-center">
                        {" "}
                        <h2>No record found</h2>
                    </div>
                )}
                {users && users.length > 0 && (
                    <DataTable selectableRows columns={columns} data={filteredUsers()} pagination />
                )}
            </div>
        </div>
    );
};

export default Index;
