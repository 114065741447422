import { Modal } from "antd";
import React, { useState } from "react";
import CardDetail from "../../CardDetails";
import { useSelector } from "react-redux";
import { getUser } from "../../../Store/selectors";

export default function CardDetailModal({ modal, setModal }) {
    const { user } = useSelector(getUser);
    const openEditModal = () => {
        setModal(true);
    };

    const closeEditModal = () => {
        setModal(false);
    };
    return (
        <Modal
            visible={modal}
            className=""
            footer={false}
            title={
                user.role === "fan" ? "Add Bank Details" : "Add Account Details"
            }
            onCancel={closeEditModal}
        >
            <CardDetail setCardModal={setModal} />
        </Modal>
    );
}
