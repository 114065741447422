import styled from "styled-components";

const Wrappper = styled.div`
    a:active {
        outline: 0;
    }
    #wrapper {
        font-family: "poppins", Arial, sans-serif !important;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.6em;
    }

    .time-expire {
        float: right;
        color: red;
        font-size: 30px;
        font-weight: bold;
        margin-right: 5px;
    }
    .time-expire.mobile {
        font-size: 22px;
        vertical-align: middle;
        line-height: 2.5;
    }
    #loadgrid {
        float: left;
        width: 100%;
        border: 0;
    }
    #loadgrid.mobile {
        display: none;
    }
    #loadgrid .tabs-left {
        /* border: 1px solid #eb1d1d; */
        width: 100%;
        float: left;
        margin-top: 0;
        margin-bottom: 0;
    }
    .title_starts {
        min-width: 250px !important;
    }
    #loadgrid.desktop .chat-title {
        float: left;
        width: 26%;
        border-right: 0px #ddd solid;
        background-color: #046d68 !important;
        height: 86.9vh;
        overflow-y: scroll;
    }
    #loadgrid.desktop .chat-title::-webkit-scrollbar {
        display: none;
    }
    #loadgrid.mobile .chat-title {
        width: 100%;
        border-right: 0px #ddd solid;
        /* height: 476px; */
        background-color: #046d68 !important;
        height: 90vh;
    }
    #loadgrid .chat-title ul {
        padding: 0;

        list-style: none;
        width: 100%;
        margin-bottom: 0;
        border: 0px;

        /* overflow: auto; overflow-x: hidden; */
    }
    #loadgrid .chat-title ul .search {
        padding: 10px 20px;
        width: 100%;
        float: left;
        border-bottom: 1px #323840 solid;
    }
    #loadgrid .chat-title ul .search .input_search {
        border: 1px solid #363d45;
        border-right: 0;
        margin: 0;
        padding: 0;
        padding-left: 12%;
        width: 100%;
        height: 34px;
        float: left;
        display: block;
        font-size: 12px;
        color: #979ea6;
        /* background-color: #046d68 !important; */

        border-radius: 50px;
    }

    /* #loadgrid .chat-title ul .title{} */
    #loadgrid .chat-title ul .title h3 {
        width: 100%;

        float: left;
        padding: 9px 18px;
        background: #2a3848;
        border: 1px #212c38 solid;
        margin: 0px;
        font-size: 18px;
        color: #fff;
        line-height: 20px;
    }
    #loadgrid .chat-title ul li {
        cursor: pointer;
        line-height: 20px;
        padding: 0px;
        border-bottom: 1px #046d68 solid;
        width: 100%;
        border-right: 0px;
    }
    #loadgrid .chat-title ul li .unread {
        color: #000;
        background: #fff;
        border-radius: 50px;
        border: 0px #212c38 solid;
        float: right;
        width: 25px;
        height: 25px;
        padding: 3px 4px;
        line-height: 21px;
        text-align: center;
        font-size: 12px;
        margin-right: 20px;
    }
    #loadgrid .chat-title ul li:last-child {
        border-right: 0px #ddd solid;
    }
    #loadgrid .chat-title ul li a {
        padding: 11px 15px 6px 15px;
        /* background-color: #47b0a1 !important; */
        width: 100%;
        color: #fff;
        float: left;
        clear: both;
        border-bottom: 1px #046d68 solid;
    }
    #loadgrid .chat-title ul li a .name {
        font-size: 12px;
        font-weight: normal;
        margin: 0px;
        width: 100%;
        float: left;
        text-align: left;
    }
    #loadgrid .chat-title ul li a .online {
        background: #25b000;
        padding: 3px;
        width: 2px;
        height: 2px; /* float: left; */
        position: relative;
        border-radius: 20px;
        top: -20px;
        right: 0;
        left: 45px;
        display: inline-block;
    }
    #loadgrid .chat-title ul li a .sub-msg {
        font-size: 10px;
        color: var(--color-light);
        margin: 5px 0;
        float: left;
        width: 80%;
        text-align: left;
    }
    #loadgrid .chat-title ul li a .min {
        font-size: 11px;
        color: var(--color-light);
        margin: 5px 0;
        float: right;
        width: 16%;
        text-align: right;
    }
    #loadgrid .chat-title ul li.active a:hover {
        background: #47b0a1;
        color: #fff;
        width: 100%;
        border-right: 0px;
    }
    #loadgrid .chat-title ul li.active a {
        background: #47b0a1;
        color: #fff !important;
        margin-left: 0px;
        border: 0px;
    }
    #loadgrid .chat-title ul li a:hover {
        background: #47b0a1;
        color: #fff;
        border-bottom: 1px #046d68 solid;
        border-top: 0px; /* float: left; */ /* width: 100%; */
        border-right: 0;
        border-left: 0px;
    }
    #loadgrid.desktop .chat-des {
        float: left;
        width: 74%;
        margin-left: 0px; /* z-index: 999999999; */
        border-left: 1px #ddd solid;
        background: #fff;
    }

    #loadgrid.mobile .chat-des {
        float: left;
        width: 100%;
        margin-left: 0px; /* z-index: 999999999; */
        border-left: 1px #ddd solid;
        background: #fff;
    }
    /* #loadgrid .chat-des .tab-pane{} */
    #loadgrid .chat-des .tab-pane .title {
        width: 100%;
        float: left;
        padding: 0;
        background: #fff;
        border-bottom: 1px #ddd solid;
        height: 55px;
    }
    #loadgrid .chat-des .tab-pane .title h3 {
        float: left;
        font-size: 16px;
        margin: 0px;
        line-height: 25px;
        color: var(--color-light);
        padding: 15px 7px;
        font-weight: 600;
    }
    #loadgrid .chat-des .tab-pane .title h3 strong {
        font-size: 10px;
        font-weight: 600;
        color: var(--color-light);
    }

    #loadgrid .chat-des .tab-pane .message-info {
        width: 100%;
        padding: 10px;
        float: left;
        /* min-height: 73.6vh; */
        height: 78.5vh;
        overflow: auto;
        overflow-x: hidden;
        background: #fff;
        color: var(--color-light);
        padding-bottom: 50px;
    }
    #loadgrid .chat-des .tab-pane .message-info .full {
        width: 108%;
        margin: 5px 0;
        float: left;
    }
    #loadgrid .chat-des .tab-pane .message-info .full img {
        border-radius: 50px;
        width: 30px;
        margin-right: 5px;
        float: left;
        margin-top: 0px;
    }
    #loadgrid .chat-des .tab-pane .message-info .full.snd_row {
        width: 108%;
        margin: 5px 0;
        float: right;
        color: var(--color-light);
        text-align: right;
    }
    #loadgrid .chat-des .tab-pane .message-info .full.snd_row img {
        float: right;
        margin-right: 0px;
        margin-left: 5px;
    }
    #loadgrid .chat-des .tab-pane .message-info .full .date-change {
        width: 100%;
        margin: 0;
        float: left;
        text-align: center;
        color: var(--color-light);
    }
    #loadgrid .chat-des .tab-pane .message-info .full {
        .text,
        .voice {
            position: relative;
            line-height: 17px;
            color: var(--color-light);

            font-weight: normal;
            font-size: 11px;
            background-color: #f6f7fb;
            padding: 6px 15px;
            white-space: pre-line;
            word-wrap: break-word;
            text-align: left;
            max-width: 85%;
            box-sizing: border-box;
            float: left;
            display: inline-block;
            border-top-left-radius: 0px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
        }
        .text:after,
        .voice:after {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: f1f3f5;
            border-right-color: #ffffff;
            border-width: 5px;
            margin-top: -6px;
        }
    }
    #loadgrid .chat-des .tab-pane .message-info .full.snd_row {
        .text,
        .voice {
            position: relative;
            line-height: 17px;
            color: var(--color-light);

            font-weight: normal;
            font-size: 11px;
            background-color: #e4f6f6;
            padding: 6px 15px;
            white-space: pre-line;
            word-wrap: break-word;
            text-align: left;
            max-width: 85%;
            box-sizing: border-box;
            float: right;
            display: inline-block;
            border-top-left-radius: 25px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
        }
        .text:after,
        .voice:after {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: d3d3d3;
            border-left-color: #ffffff;
            border-width: 5px;
            margin-top: -6px;
        }
    }
    #loadgrid .chat-des .tab-pane .message-info .full h5 {
        color: #66696a;
        font-size: 9px;
        display: inline-block;
        width: 91%;
        margin-bottom: 0px;
        float: left;
        margin-left: 38px;
    }
    #loadgrid .chat-des .tab-pane .message-info .full.snd_row h5 {
        color: #66696a;
        font-size: 9px;
        display: inline-block;
        width: 91%;
        margin-bottom: 0px;
        text-align: right;
        float: right;
        margin-right: 38px;
    }
    .reply {
        position: absolute;
        bottom: 0 !important;
        float: left;
        width: 71%;
        border-top: 1px #ddd solid;
        padding: 0;
        background: #fff;
    }
    #loadgrid .chat-des .tab-pane .reply .reply-area {
        width: 70%;
        float: left;
    }
    #loadgrid .chat-des .tab-pane .reply .attach {
        line-height: 20px;
        color: #282e35;
        padding: 11px 3px;
        width: 15%;
        height: 44px;
        text-align: center;
        border: 0px #ddd solid;
        margin: -1px;
        cursor: pointer;
        float: left;
        border-right: 0px;
    }
    #loadgrid .chat-des .tab-pane .reply .reply-area textarea {
        border-radius: 0px;
        box-shadow: none;
        margin-right: 0px;
        float: left;
        resize: none;
        border: 0px;
        font-size: 12px;
        height: 44px;
    }
    #loadgrid .chat-des .tab-pane .reply .reply-submit {
        border-radius: 0px;
        box-shadow: none;
        float: left;
        width: 15%;
    }
    #loadgrid .chat-des .tab-pane .reply .reply-submit .btn {
        box-shadow: none;
        margin-left: 5px;
        float: right;
        padding: 7px;
        font-size: 13px;
        text-transform: uppercase;
        background: #046d68;
        color: #777;
        margin-top: 5px;
        border: 1px #777 solid;
        border-radius: 40px;
        font-weight: 600;
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
    }
    /* @media (max-width: 1280px) and (max-height: 800px) {
    .message-info {
      height: 82.3vh !important;
    }
    #loadgrid.desktop .chat-title {
      height: 89vh !important;
    }
  } */
    @media (max-width: 1024px) and (max-height: 600px) {
        .message-info {
            height: 76.3vh !important;
        }
        #loadgrid.desktop .chat-title {
            height: 85.5vh !important;
        }
    }

    @media (max-width: 768px) {
        #loadgrid.mobile {
            display: block;
        }
        #loadgrid.desktop {
            display: none;
        }
        .reply {
            position: sticky;
            width: 100% !important;
        }
        .message-info {
            height: 78.5vh !important;
        }
    }
    @media (max-width: 540px) {
        /* .message-info {
      height: 80vh !important;
    } */
    }
    @media (max-width: 400px) {
        #loadgrid .chat-des .tab-pane .reply .attach {
            line-height: 10px;
            color: #282e35;
            padding: 11px 0px;
            width: 18%;
            height: 44px;
            text-align: center;
            border: 0px #ddd solid;
            /* margin: -1px; */
            cursor: pointer;
            float: left;
            /* border-right: 0px; */
        }
        #loadgrid .chat-des .tab-pane .reply .reply-area {
            width: 67%;
            float: left;
        }
        #loadgrid.mobile.message-info {
            height: 80.5vh !important;
        }
    }
`;
export default Wrappper;
