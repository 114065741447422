import { getSuggestedQuery } from "@testing-library/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import styled from "styled-components";
import { getUser } from "../../../Store/selectors";
import Button from "../../../components/Forms/Button.js";
import Wrappper from "./Wrappper";
import Input from "../../../components/Forms/Input";
import { AiOutlineLink } from "react-icons/ai";
import {
    get_bank_details,
    get_card_details,
    remove_bank_details,
    remove_card_details,
    update_user,
    update_user_name,
} from "../../../Store/Reducers/Auth/actions";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import Check from "../../../assets/icons/check.svg";
import CardDetail from "../../../components/CardDetails";
import CardDetailModal from "../../../components/modals/CardDetailModal";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
function Profile(props) {
    const navigate = useNavigate();
    const { user, card_details, bank_details } = useSelector(getUser);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        name: user.name,
        bio: user.bio,
    });
    const [username, setUsername] = useState(user.username);

    useEffect(() => {
        if (user.role === "fan") dispatch(get_card_details());
        else dispatch(get_bank_details());
    }, []);

    const [card_modal, setCardModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [username_modal, setUserNameModal] = useState(false);

    const openEditModal = () => {
        setModal(true);
    };

    const closeEditModal = () => {
        setModal(false);
    };
    const closeEditUserNameModal = () => {
        setUserNameModal(false);
    };

    const update = () => {
        if (data.name === "" || data.bio === "") {
            ToastTopRight.fire({
                icon: "error",
                title: "Both fields are required!",
            });
            return;
        }
        let obj = {
            data,
            success: () => {
                setModal(false);
            },
            failure: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
            },
        };
        dispatch(update_user(obj));
    };

    const updateUserName = () => {
        if (username === "") {
            ToastTopRight.fire({
                icon: "error",
                title: "Please Enter Username!",
            });
            return;
        }
        let obj = {
            data: { username },
            success: () => {
                navigate("/dashboard/" + username);
                setUserNameModal(false);
            },
            failure: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
            },
        };
        dispatch(update_user_name(obj));
    };

    const copyLink = () => {
        ToastTopRight.fire({
            icon: "success",
            text: "Link Copied!",
        });
        navigator.clipboard.writeText(window.location.hostname + "/" + user.username);
    };

    const blobToImage = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.onerror = reject;
                img.src = reader.result;
            };
            reader.readAsDataURL(blob);
        });
    };
    return (
        <Wrappper>
            <header>
                <div class="container">
                    <div class="profile">
                        <div class="profile-image">
                            {user.profile_pic ? (
                                <>
                                    <img src={user.profile_pic} alt="" id="profile_pic" />
                                    <h4 onClick={() => setUserNameModal(true)}>
                                        @{user.username || "add username"} <BiEdit color="red" />
                                    </h4>
                                </>
                            ) : (
                                <img
                                    src="https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&h=152&fit=crop&crop=faces"
                                    alt=""
                                />
                            )}
                        </div>

                        <div class="profile-user-settings">
                            <h1 class="profile-user-name">{user.name}</h1>
                            <Button className={"btn profile-edit-btn"} onClick={openEditModal}>
                                Edit
                            </Button>
                            {/* <button class="btn profile-edit-btn">Edit Profile</button> */}

                            <button class="btn profile-settings-btn" aria-label="profile settings">
                                <i class="fas fa-cog" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div class="profile-bio">
                            {user?.role !== "fan" && (
                                <>
                                    <span
                                        style={{
                                            color: "#529a91",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {window.location.hostname + "/" + user.username}
                                    </span>
                                    <AiOutlineLink
                                        color="black"
                                        size={"30px"}
                                        onClick={copyLink}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />{" "}
                                </>
                            )}
                            <p>
                                <span class="profile-real-name"> {user.bio}</span>{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <div class="gallery">
                <Actions
                    user={user}
                    card_details={card_details}
                    bank_details={bank_details}
                    setCardModal={setCardModal}
                />
            </div>

            <Modal visible={modal} className="" footer={false} onCancel={closeEditModal}>
                <EditProfileWrapper>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Edit Profile</h3>
                            </div>
                            <div className="col-md-12 mt-3">
                                <Input
                                    type={"text"}
                                    name="name"
                                    value={data.name}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        })
                                    }
                                    placeholder="Enter Name..."
                                />{" "}
                            </div>
                            <div className="col-md-12">
                                <textarea
                                    rows={3}
                                    placeholder="enter bio "
                                    value={data.bio}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            bio: e.target.value,
                                        })
                                    }
                                >
                                    {user.bio}
                                </textarea>
                            </div>
                        </div>
                        <div className="submit">
                            <Button className={"btn btn-primaryy w-100"} onClick={() => update()}>
                                Update
                            </Button>
                        </div>
                    </div>
                </EditProfileWrapper>
            </Modal>
            <Modal visible={username_modal} className="" footer={false} onCancel={closeEditUserNameModal}>
                <EditProfileWrapper>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Edit Username</h3>
                            </div>
                            <div className="col-md-12 mt-3">
                                <Input
                                    type={"text"}
                                    name="name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter User Name..."
                                />{" "}
                            </div>
                        </div>
                        <div className="submit">
                            <Button className={"btn btn-primaryy w-100"} onClick={() => updateUserName()}>
                                Update
                            </Button>
                        </div>
                    </div>
                </EditProfileWrapper>
            </Modal>

            {/* CardDetail Modal */}
            <CardDetailModal modal={card_modal} setModal={setCardModal} />
        </Wrappper>
    );
}

export default Profile;

const Actions = ({ user, setCardModal, card_details, bank_details }) => {
    const dispatch = useDispatch();
    const removeCard = () => {
        if (window.confirm("Are you sure to remove card from your account? ")) {
            dispatch(remove_card_details());
        }
    };

    const removeBank = () => {
        if (window.confirm("Are you sure to remove bank account? ")) {
            dispatch(remove_bank_details());
        }
    };
    const activateAccount = () => {
        window.location.replace(bank_details?.connectLink);
    };

    const showAccountInfo = () => {
        if (user.role === "fan") {
            return card_details?.card_id ? (
                <>
                    <tr onClick={() => setCardModal(true)}>
                        <th className="action_name">
                            <button onClick={removeCard}>X</button>
                            {card_details.card_number}
                        </th>
                        <th className="action_button">
                            {card_details?.card_id ? (
                                <img src={Check} alt="all set" />
                            ) : (
                                <span className={"btn btn-primaryy w-100"}>Add</span>
                            )}
                        </th>
                    </tr>
                </>
            ) : (
                <tr onClick={() => setCardModal(true)}>
                    <th className="action_name">Add Bank Details</th>
                    <th className="action_button">
                        {card_details?.id ? (
                            <img src={Check} alt="all set" />
                        ) : (
                            <span className={"btn btn-primaryy w-100"}>Add</span>
                        )}
                    </th>
                </tr>
            );
        } else {
            return bank_details?.id ? (
                <>
                    {bank_details?.status ? (
                        <tr onClick={() => setCardModal(true)}>
                            <th className="action_name">
                                <button onClick={removeBank}>X</button>
                                <span>
                                    {bank_details?.account_holder_name} (**** ****{" "}
                                    {bank_details?.account_number.toString().substr(8)})
                                </span>
                            </th>
                            <th className="action_button">
                                {bank_details?.id ? (
                                    <img src={Check} alt="all set" />
                                ) : (
                                    <span className={"btn btn-primaryy w-100"}>Add</span>
                                )}
                            </th>
                        </tr>
                    ) : (
                        <tr>
                            <th colSpan={5} className="text-center">
                                <div class="btn-4" onClick={activateAccount}>
                                    Activate Account
                                </div>
                            </th>
                        </tr>
                    )}
                </>
            ) : (
                <tr onClick={() => setCardModal(true)}>
                    <th className="action_name">Add Account Details</th>
                    <th className="action_button">
                        {bank_details?.id ? (
                            <img src={Check} alt="all set" />
                        ) : (
                            <span className={"btn btn-primaryy w-100"}>Add</span>
                        )}
                    </th>
                </tr>
            );
        }
    };
    return (
        <ActionStyle>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th colSpan={8}>{user?.role === "fan" ? "CARD DETAILS" : "ACCOUNT DETAILS"}</th>
                    </tr>
                </thead>
                <tbody>{showAccountInfo()}</tbody>
            </table>
        </ActionStyle>
    );
};

const ActionStyle = styled.div`
    .table {
        width: 80%;
        margin-left: 10%;
        border: none;
        tbody {
            border: none;
        }
        .action-bar {
            border: 1px solid red;
        }

        .action_name {
            width: 80%;
            color: var(--color-dark);
            letter-spacing: 2px;
            vertical-align: middle;
            line-height: 1;
            font-size: 20px;
            button {
                border: none;
                border-radius: 50%;
                padding: 3px 5px;
                background-color: black;
                color: white;
                font-weight: bold;
                margin-right: 10px;
                font-size: 16px;
            }
        }
        .action_button {
            width: 20%;
            color: var(--color-dark);
            font-weight: 100;
            text-decoration: underline;
            img {
                width: 50px;
                height: 50px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    @media screen and (max-width: 572px) and (min-width: 220px) {
        .checkImg {
            width: 60px;
            height: 60px;
        }
        .action_name {
            font-size: 14px;
        }
    }
`;

const EditProfileWrapper = styled.div`
    h3 {
        color: var(--color-dark);
    }
    textarea {
        width: 100%;
        padding: 20px;
        margin: 10px 0px;
        border: 1px solid lightgrey;
        border-radius: 8px;
        outline: none;
    }

    .table {
        width: 80%;
        .action_name {
        }
    }
`;
