import styled from "styled-components";

const Wrapper = styled.div`
    .circles {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        .circle.active {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: var(--color-dark);
            margin-right: 5px !important;
        }
        .circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #f4ebff;
            margin-right: 5px !important;
        }
    }

    .vertical {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 775px) {
        .vertical {
            display: none;
        }
    }

    .page-texts {
        h6 {
            font-size: 30px;
            font-weight: 600;
            color: var(--color-dark);
            margin-bottom: 0px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-light);
            margin-bottom: 0px;
        }
    }

    .language {
        .card:first-child {
            background-color: aqua !important;
        }
    }

    .account {
        p {
            font-size: 14px;
            color: var(--color-light);
            font-weight: 400;
        }
        p span {
            font-size: 14px;
            color: var(--color-dark);
            font-weight: 500;
        }
    }

    .form-check-label {
        font: 14px;
        color: var(--color-light);
    }

    .account a {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-dark);
    }

    .light-text {
        font-weight: 500;
        font-size: 16px;
        color: var(--col-dark);
    }

    .select-img {
        border: 2px solid transparent;
        border-radius: 4px;
    }

    .gotoLogin {
        color: var(--color-light);
    }
    .darkimg {
        border: 3px solid var(--color-light) !important;
        border-radius: 8px;
    }

    .picture {
        width: 200px;
        height: 200px;
        background: transparent;

        #file {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid var(--color-light);
            border-radius: 50%;
            cursor: pointer;

            :hover {
                border: none;
            }
        }
    }

    .started-cards {
        width: 300px;
        height: auto;
        background: white;
        border: 1px solid #d0d5dd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .started-cards.active {
        background: var(--color-light);
    }

    .language .started-cards:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .language .started-cards:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .started-cards-style {
        width: 300px;
        height: auto;
        background: white;
        border: 1px solid #d0d5dd;
        cursor: pointer;
        padding-top: 30px;
        /* border: 1px solid #000; */

        img {
            border-radius: 100px;
        }
    }

    .started-cards-style.active {
        border: 2px solid var(--color-light);
        color: var(--color-dark);
    }

    .language .started-cards-style:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .language .started-cards-style:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .checkout:hover {
        color: #53389e;
    }

    .btn-primaryy {
        height: 44px;
        width: 200px !important;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px !important;
        transition: ease-in-out 0.2s;
        padding-top: 6px;
    }
    .btn-SupplierPrimary {
        height: 44px;
        width: 510px !important;
        box-sizing: border-box;
        background-color: #7f56d9 !important;
        border: 1px solid #7f56d9;
        color: white !important;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px !important;
        transition: ease-in-out 0.2s;
        padding-top: 6px;
    }
    .btn-align {
        padding-top: 10px !important;
    }
    .btn-primaryy:focus {
        background-color: #7f56d9 !important;
        border: 1px solid #7f56d9;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff !important;
        color: white !important;
        /* font-size: 16px;
  font-weight: 400;
  border-radius: 4px !important; */
    }
    .supplier_deactivate {
        height: 44px;
        width: 250px !important;
        box-sizing: border-box;
        background-color: #b42318 !important;
        border: 1px solid #b42318;
        color: white !important;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px !important;
        transition: ease-in-out 0.2s;
        padding-top: 6px;
    }
    .supplier_Primary {
        width: 250px !important;
    }
    .primary-color {
        color: var(--col-dark) !important;
    }

    .color-green {
        color: var(--color-green) !important;
    }
    .btn-primaryy:hover {
        background-color: var(--color-dark) !important;
        border: 1px solid var(--color-dark) !important;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
    .get-start-text {
        img {
            margin-left: 210px;
            /* position: absolute; */
        }

        h6 {
            font-size: 60px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-light);
        }
    }
  
  .container-influencer {
    .get-start-text {
      position: absolute;

      @media (max-width: 991px) {
        position: relative;
      }
    }
  }
`;

export default Wrapper;
