import React, { useEffect } from "react";
import { FaExclamation, FaBeer } from "react-icons/fa";
import Button from "../components/Forms/Button";
import Footer from "../components/LandingPg/Footer";
import Header from "../components/LandingPg/Header";
import Navbar from "../components/LandingPg/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Store/Reducers/Auth/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../Store/selectors";

function Welcome(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, tokens } = useSelector(getUser);

    useEffect(() => {
        if (user?.id && tokens) {
            if (user.role !== "admin") {
                navigate("/dashboard/" + user.username)
            } else {
                navigate("/admin/dashboard/")
            }
        } else {
            dispatch(logout());
        }
    }, []);
    return (
        <div
            style={{
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <Navbar />
            <Header />
            <Footer />
        </div>
    );
}

export default Welcome;
