import React, { useEffect } from "react";
import TopBar from "../../components/dashboard/TopBar";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Influencers from "./Influencers";
import Profile from "./Profile";
import Wallet from "./Wallet";
import Messages from "./Messages/Index";
import { useDispatch, useSelector } from "react-redux";
import { get_packages } from "../../Store/Reducers/Dashboard/actions";
import { getDashboard, getUser } from "../../Store/selectors";
import Packages from "./Packages/Index";
import ChangePassword from "./ChangePassword";
import { get_card_details } from "../../Store/Reducers/Auth/actions";
import VoiceMsg from "./Messages/Voicemsg";

function Dashboard(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { packages } = useSelector(getDashboard);
    const { user } = useSelector(getUser);

    useEffect(() => {
        dispatch(get_packages());
        dispatch(get_card_details());
    }, []);
    return (
        <div>
            <div className="container-fluid ">
                <div className="row ">
                    <div className="col-md-12 p-0 m-0">
                        <TopBar />
                    </div>
                    <div className="col-md-12 p-0">
                        <Routes>
                            <Route index element={<Influencers />} />
                            {/* <Route path="voicemsg" element={<VoiceMsg />} /> */}

                            <Route path="messages" element={<Messages />} />
                            <Route path={user?.username} element={<Profile />} />
                            <Route path="wallet" element={<Wallet />} />
                            <Route path="/change-password" element={<ChangePassword />} />

                            {/* <Route path="packages" element={<Packages />} /> */}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
