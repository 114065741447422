import React from "react";
import { BiCheckCircle, BiCross } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import styled from "styled-components";

const SingleMessage = ({ user, msg }) => {
    const renderMessageByType = () => {
        if (msg.type === "text") {
            return <span className="text">{msg.content}</span>;
        }
        if (msg.type === "voice") {
            return (
                <span className="voice">
                    <audio src={msg.content} controls />
                </span>
            );
        }
        // if(msg.type === "text") {

        // }
    };

    const checkMsgStatus = () => {
        if (msg.responded) {
            return (
                <BiCheckCircle
                    title="replied"
                    color="green"
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto"
                    }}
                    size={"24px"}
                />
            );
        } else if (msg.responded === undefined || msg.responded === null) {
            return (
                <BiCross
                    title="expired"
                    color="red"
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto"
                    }}
                    size={"24px"}
                />
            );
        } else if (!msg.responded) {
            return (
                <BsExclamationCircle
                    title="waiting for reply"
                    color="lightblue"
                    style={{
                        marginTop: "auto",
                        marginBottom: "auto"
                    }}
                    size={"24px"}
                />
            );
        }
    };
    return (
        <>
            {msg.sender_id === user.id ? (
                <div className="full snd_row">
                    {user.role === "fan" ? checkMsgStatus() : ""}

                    {renderMessageByType()}
                    <h5>
                        {new Date(msg.timestamp)
                            .toISOString()
                            .slice(0, 16)
                            .replace("T", " ")}
                    </h5>
                </div>
            ) : (
                <div className="full">
                    {renderMessageByType()}
                    <h5>
                        {new Date(msg.timestamp)
                            .toISOString()
                            .slice(0, 16)
                            .replace("T", " ")}
                    </h5>
                    {user.role !== "fan" ? checkMsgStatus() : ""}
                </div>
            )}
        </>
    );
};

export default SingleMessage;

const Wrapper = styled.div``;
