export const Data = [
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar1.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar2.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar3.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar4.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
  {
    img: "https://bootdey.com/img/Content/avatar/avatar7.png",
    name: "Brooke Kelly",
    desc: "Information Technology Rolling Meadows, IL 60008",
    btnText: "MESSAGE",
  },
];
