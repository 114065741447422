import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Authentications/Login";
import Signup from "./pages/Authentications/Signup";
import Dashboard from "./pages/dashboard";
import AdminDashboard from "./pages/admin";
import AuthLayout from "./pages/Layouts/AuthLayout";
import AdminCheck from "./pages/Layouts/AdminCheck";
import Welcome from "./pages/Welcome";
import { getUser } from "./Store/selectors";
import PubliProfile from "./pages/PubliProfile";

function App() {
    const dispatch = useDispatch();
    const { user } = useSelector(getUser);

    useEffect(() => {
        if (user?.role === "fan") {
        }
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route index element={<Welcome />} />
                    <Route path="/:name" element={<PubliProfile />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />

                    <Route
                        index
                        path="/dashboard/*"
                        element={
                            <AuthLayout>
                                <Dashboard />
                            </AuthLayout>
                        }
                    />
                    <Route
                        index
                        path="/admin/dashboard/*"
                        element={
                            <AdminCheck>
                                <AdminDashboard />
                            </AdminCheck>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
