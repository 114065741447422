import { ToastTopRight } from "../../components/Toasts/top-right";

export const ErrorHandler = (error) => {
    if (error?.response?.data) {
        let flash_error_msg = "";
        if (error.response.data?.flash_messages?.length > 0) {
            flash_error_msg = error.response.data?.flash_messages[0].message;
        } else if (error.response.data.msg) {
            flash_error_msg = error.response.data.msg;
        } else if (error.response.data.message) {
            flash_error_msg = error.response.data.message;
        } else if (error.response.data.error) {
            flash_error_msg =
                // typeof error.response.data.error === "object"
                //   ? error.response.data.error[Object.keys(error.response.data.error)[0]]
                //   : error.response.data.error;
                typeof error.response.data.error === "object"
                    ? Object.keys(error.response.data.error)[0].toUpperCase() +
                    ": " +
                    error.response.data.error[Object.keys(error.response.data.error)[0]]
                    : error.response.data.error;
        }
        if (error.response.data.code === 401) {
            ToastTopRight.fire({
                icon: "error",
                text: "Session Out, Please Login again!",
            });
            localStorage.clear();
            setTimeout(() => {
                window.location.replace("/");
                localStorage.clear();
            }, 1000);
        }
        return {
            code: error.response.data.code,
            msg: flash_error_msg ? flash_error_msg : "",
        };
    } else {
        return {
            code: 0,
            msg: "Please check your internet connection",
        };
    }
};
// const flash_error_msg =
//   error.response.data?.flash_messages.length > 0
//     ? error.response.data?.flash_messages[0].message
//     : error.response.data.msg;
