import React, { useEffect, useState } from "react";
import MessageBox from "../components/MessageBox/Index";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard, getUser } from "../Store/selectors";
import { purchase_packages, setLoading, get_packages } from "../Store/Reducers/Dashboard/actions";
import { ToastTopRight } from "../components/Toasts/top-right";
import CardDetailModal from "../components/modals/CardDetailModal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { basePath } from "../Store/Config/config";

function PubliProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { packages } = useSelector(getDashboard);
    const { user, card_details } = useSelector(getUser);
    const [card_modal, setCardModal] = useState(false);
    const [userByUsername, setUserByUsername] = useState({});

    const pkgPurchase = (pkg) => {
        if (!user?.id) {
            ToastTopRight.fire({
                icon: "error",
                title: "Login first to message !",
            });
            setTimeout(() => {
                navigate("/signup", { state: { type: 1 } });
            }, 1000);
            return;
        }
        if (user.role !== "fan") {
            ToastTopRight.fire({
                icon: "error",
                title: "Only Fan can buy!",
            });
            return;
        }
        if (!card_details?.id && user.credits < 4) {
            ToastTopRight.fire({
                icon: "error",
                title: "Please Add Card Details First!",
            });
            setCardModal(true);
            // navigate(user.name);
            return;
        }
        let payload = {
            data: pkg,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Purchased Successfully !",
                });
                dispatch(setLoading(false));
            },
            error: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                navigate("/dashboard/" + user.username);
                dispatch(setLoading(false));
            },
        };
        dispatch(purchase_packages(payload));
    };

    useEffect(() => {
        dispatch(get_packages());
        const name = location.pathname.split("/")[1];
        axios
            .get(`${basePath}users/username/${name}`)
            .then((res) => {
                setUserByUsername(res?.data?.user);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    return (
        <div>
            <CardDetailModal modal={card_modal} setModal={setCardModal} />
            <MessageBox
                isModalOpen={true}
                handleCancel={() => {}}
                influencer={userByUsername}
                packages={packages}
                pkgPurchase={pkgPurchase}
                dont={true}
            />
        </div>
    );
}

export default PubliProfile;
