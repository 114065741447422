import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Button({ children, link, onClick, className, style }) {
  return (
    <Wrapper className={className} onClick={onClick} style={style}>
      <Link to={link}>{children}</Link>
    </Wrapper>
  );
}

export default Button;

const Wrapper = styled.button`
  /* border: none;
  background-color: var(--color-light);
 
  font-weight: bold;
  font-size: 20px; */

  text-align: center;
  /* display: inline-block; */
  position: relative;
  overflow: hidden;
  background-color: #e91d63 !important;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  /* position: absolute; */
  color: black !important;

  :before {
    content: "";
    position: absolute;
    height: 150px;
    width: 50px;
    background: #fff;
    right: -40px;
    top: -40px;
    transform: rotate(37deg);
    transition: all 0.3s;
    opacity: 0.3;
  }

  :hover:before {
    right: 105%;
  }
  a {
    text-decoration: none;
    color: white;
  }
`;
