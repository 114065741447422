export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const GET_PACKAGES = "GET_PACKAGES";
export const SET_PACKAGES = "SET_PACKAGES";
export const PURCHASE_PACKAGES = "PURCHASE_PACKAGES";
export const SET_CHATS = "SET_CHATS";
export const GET_CHATS = "GET_CHATS";
export const CREATE_CHAT = "CREATE_CHAT";
export const SEND_MESSAGE_TO_INFLUENCER = "SEND_MESSAGE_TO_INFLUENCER";
export const SEND_MESSAGE_TO_FAN = "SEND_MESSAGE_TO_FAN";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_CHATLOADING = "SET_CHATLOADING";

export const get_users = (payload) => ({
    type: GET_USERS,
    payload,
});
export const set_users = (payload) => ({
    type: SET_USERS,
    payload,
});
export const send_message = (payload) => ({
    type: SEND_MESSAGE,
    payload,
});

export const get_packages = (payload) => ({
    type: GET_PACKAGES,
    payload,
});

export const set_packages = (payload) => ({
    type: SET_PACKAGES,
    payload,
});

export const purchase_packages = (payload) => ({
    type: PURCHASE_PACKAGES,
    payload,
});

export const get_chats = (payload) => ({
    type: GET_CHATS,
    payload,
});

export const set_chats = (payload) => ({
    type: SET_CHATS,
    payload,
});

export const create_chat = (payload) => ({
    type: CREATE_CHAT,
    payload,
});

export const send_message_to_fan = (payload) => ({
    type: SEND_MESSAGE_TO_FAN,
    payload,
});

export const send_message_to_influencer = (payload) => ({
    type: SEND_MESSAGE_TO_INFLUENCER,
    payload,
});

export const set_error = (payload) => ({
    type: SET_ERROR,
    payload,
});

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setChatLoading = (payload) => ({
    type: SET_CHATLOADING,
    payload,
});
