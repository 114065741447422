import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Check from "../../assets/icons/check.svg";
import Fan from "../../assets/images/fan.jpg";
import Celebrity from "../../assets/images/celebrity.jpg";
import Spinner from "../../components/Spinner";
import Wrapper from "./Signup.styled";
import Input from "../../components/Forms/Input";
import Button from "../../components/Forms/Button.js";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Login from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { register, setLoading, update_user, update_user_name } from "../../Store/Reducers/Auth/actions";
import { ToastTopRight } from "../../components/Toasts/top-right";
import { upload_media } from "../../Store/utils/Cloudinary";
import { getUser } from "../../Store/selectors";
import heic2any from "heic2any";

function Signup(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { loading } = useSelector(getUser);
    const [screen, setScreen] = useState(1);
    const [type, setType] = useState(1);
    const [image, setImage] = useState(null);
    const [data, setData] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        profile_pic: "",
    });
    const [errField, setErrField] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        profile_pic: "",
    });
    const [card, setCard] = useState({
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
    });

    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    useEffect(() => {
        setType(location.state?.type || 1);

        if (location.state?.type === 2) {
            document.body.style.setProperty('background', '#f09ad9', 'important');
        } else {
            document.body.style.removeProperty('background');
        }

        return () => {
            document.body.style.removeProperty('background');
        }
    }, [location]);
    //   const handleInputFocus = (e) => {
    //     setCard({ ...card, focus: e.target.name });
    //   };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCard({ ...card, [name]: value });
    };
    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setData({ ...data, [name]: value });
    };
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };
    // validation
    const clearErrors = () => {
        errField.name = "";
        errField.email = "";
        errField.password = "";
        errField.username = "";
        setErrField(errField);
    };
    const checkValidation = () => {
        clearErrors();

        var error = false;
        const errForm = { name: "", email: "", password: "", username: "" };
        /* eslint-disable */
        Object.keys(data).map(function (keyName, keyIndex) {
            const value = data[keyName];
            if (keyName === "username") {
                var usernameRegex = /^[a-zA-Z0-9]+$/;
                if (value.length < 4) {
                    errForm.username = "ⓘ Username Must Be 4 characters long!";
                    error = true;
                } else if (!usernameRegex.test(value)) {
                    errForm.username = "ⓘ username shouldn't have space or special characters in it!";
                    error = true;
                } else {
                }
            }
            if (keyName === "profile_pic") {
                if (value === "") {
                    errForm.profile_pic = "ⓘ Profile Image Is Required!";
                    error = true;
                }
            }
            if (keyName === "name") {
                if (value === "") {
                    errForm.name = "ⓘ Name Is Required!";
                    error = true;
                }
            }
            if (keyName === "email") {
                if (value === "") {
                    errForm.email = "ⓘ Email Address Is Required!";
                    error = true;
                } else if (!validateEmail(value)) {
                    errForm.email = "ⓘ Invalid Email Address!";
                    error = true;
                }
            }

            if (keyName === "password") {
                if (value === "") {
                    errForm.password = "ⓘ Password Is Required!";
                    error = true;
                } else if (value.length > 16 || value.length < 8) {
                    errForm.password = "ⓘ Password Should Be Between 8 And 16 Characters!";
                    error = true;
                }
            }
            setErrField(errForm);
        });
        return error;
    };

    const handleSubmit = async () => {
        dispatch(setLoading(true));

        if (checkValidation()) {
            dispatch(setLoading(false));
            return;
        }

        submit();
        // if (type === 1) submit();
        // else setScreen(screen + 1);
    };

    const SignUp = () => {
        return (
            <Wrapper>
                <div className={`container ${type === 2 && 'container-influencer'}`}>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center">
                                {/* <div className="logo">
                                    <h5>Logo</h5>
                                    <img src={Logo} alt="logo" /> 
                                </div> */}
                                <div className="get-start-text mt-1">
                                    <h6>Influencer <br/>Sign Up</h6>
                                    {/* <p className="mt-12p">
                                        {type === 1
                                            ? "IKnowEm helps you to find your favorite ones!"
                                            : "Earn more from your loves ones!"}
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <center>
                            <div className="flex-column justify-content-center ">
                                <div className="col-lg-4 mt-3">
                                    <div className="picture" onClick={chooseFile}>
                                        <input type={"file"} id="file" onChange={selectFile} accept=".jpg, .png" />
                                        {data.profile_pic ? (
                                            <img src={data.profile_pic} className="profile_pic" />
                                        ) : (
                                            <img
                                                src="https://w7.pngwing.com/pngs/613/636/png-transparent-computer-icons-user-profile-male-avatar-avatar-heroes-logo-black-thumbnail.png"
                                                className="profile_pic"
                                            />
                                        )}
                                    </div>
                                    {errField.profile_pic !== "" ? (
                                        <div className="errors" style={{ textAlign: "center" }}>
                                            <span className="form-text text-danger">{errField.profile_pic}</span>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="col-lg-4 mt-3">
                                    {type !== 1 && (
                                        <span
                                            style={{
                                                color: "#e91d63",
                                                display: 'block',
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            Full name will not be displayed for fans{" "}
                                        </span>
                                    )}

                                    <Input
                                        type={"input"}
                                        name="name"
                                        className={`form-control ${
                                            errField.name !== "" ? "error-field is-invalid" : ""
                                        }`}
                                        value={data.name}
                                        placeholder="Enter Full name"
                                        onChange={handleInput}
                                        appearance={type === 2 && 'second'}
                                    />
                                    {errField.name !== "" ? (
                                        <div className="errors" style={{ textAlign: "left" }}>
                                            <span className="form-text text-danger">{errField.name}</span>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="col-lg-4 mt-3">
                                    <Input
                                        className={`form-control ${
                                            errField.username !== "" ? "error-field is-invalid" : ""
                                        }`}
                                        type={"text"}
                                        placeholder="Enter username"
                                        onChange={handleInput}
                                        value={data.username}
                                        name="username"
                                        appearance={type === 2 && 'second'}
                                    />
                                    {errField.username !== "" ? (
                                        <div className="errors" style={{ textAlign: "left" }}>
                                            <span className="form-text text-danger">{errField.username}</span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-lg-4 mt-3">
                                    <Input
                                        className={`form-control ${
                                            errField.email !== "" ? "error-field is-invalid" : ""
                                        }`}
                                        type={"email"}
                                        placeholder="Enter email address"
                                        onChange={handleInput}
                                        value={data.email}
                                        name="email"
                                        appearance={type === 2 && 'second'}
                                    />
                                    {errField.email !== "" ? (
                                        <div className="errors" style={{ textAlign: "left" }}>
                                            <span className="form-text text-danger">{errField.email}</span>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="col-lg-4 mt-3">
                                    <Input
                                        name="password"
                                        type={"password"}
                                        className={`form-control ${
                                            errField.password !== "" ? "error-field is-invalid" : ""
                                        }`}
                                        placeholder="Enter password"
                                        value={data.password}
                                        onChange={handleInput}
                                        appearance={type === 2 && 'second'}
                                    />
                                    {errField.password !== "" ? (
                                        <div className="errors" style={{ textAlign: "left" }}>
                                            <span className="form-text text-danger">{errField.password}</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </center>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center mt-5">
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <Button className={"btn btn-primaryy w-100"} onClick={() => handleSubmit()}>
                                        Next
                                    </Button>
                                )}
                                {/* // <button
                //   className="btn btn-primaryy w-100 "
                //   onClick={() => setScreen(2)}>
                //   Next
                // </button> */}
                                <div className="account mt-3">
                                    <Link to="/login" onClick={() => setScreen(screen - 1)}>
                                        <span>
                                            <BiArrowBack fontSize={"20px"} />
                                        </span>
                                        &nbsp; Already have an Account ? SignIn
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    };
    const AddBank = () => {
        return (
            <Wrapper>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center">
                                {/* <div className="logo">
                                    <h5>Logo</h5>
                                    <img src={Logo} alt="logo" /> 
                                </div> */}
                                <div className="get-start-text mt-5">
                                    <h6>Add Your Bank Details</h6>
                                    <p className="mt-12p">
                                        How do you receive money from your loved ones,
                                        <br /> please make sure your card is active for online transactions!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <center>
                            <div className="flex-column justify-content-center ">
                                <Card
                                    cvc={card.cvc}
                                    expiry={card.expiry}
                                    focused={card.focus}
                                    name={card.name}
                                    number={card.number}
                                />
                                <div className="col-lg-4 mt-3">
                                    <Input
                                        type={"number"}
                                        name="number"
                                        onChange={handleInputChange}
                                        placeholder="Enter Credit/Debit Card Number"
                                        appearance={type === 2 && 'second'}
                                    />
                                </div>

                                <div className="col-lg-4 mt-3">
                                    <Input
                                        type={"text"}
                                        name="name"
                                        onChange={handleInputChange}
                                        placeholder="Enter Full name"
                                        appearance={type === 2 && 'second'}
                                    />
                                </div>

                                <div className="col-lg-4 mt-3">
                                    <Input
                                        type={"text"}
                                        name="expiry"
                                        onChange={handleInputChange}
                                        placeholder="Enter Expiry Date"
                                        appearance={type === 2 && 'second'}
                                    />
                                </div>

                                <div className="col-lg-4 mt-3">
                                    <Input
                                        type={"number"}
                                        name="cvc"
                                        onChange={handleInputChange}
                                        placeholder="Enter CVC"
                                        appearance={type === 2 && 'second'}
                                    />
                                </div>
                            </div>
                        </center>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center mt-5">
                                <Button className={"btn btn-primaryy w-100"} onClick={() => submit()}>
                                    Next
                                </Button>
                                {/* // <button
                //   className="btn btn-primaryy w-100 "
                //   onClick={() => setScreen(2)}>
                //   Next
                // </button> */}
                                <div className="account mt-5">
                                    <Link to="#/" onClick={() => setScreen(screen - 1)}>
                                        <span>
                                            <BiArrowBack fontSize={"20px"} />
                                        </span>
                                        &nbsp; Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    };

    const ChooseType = () => {
        return (
            <Wrapper>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="text-center mt-5">
                                {/* <div className="logo">
                                    <h5>Logo</h5>
                                    <img src={Logo} alt="logo" /> 
                                </div> */}
                                <div className="page-texts">
                                    <h6>Choose Account Type</h6>
                                    <p className="mt-12p">
                                        If you want to earn money and have love ones than choose Influencer!
                                    </p>
                                </div>
                            </div>

                            <div className="d-block d-md-flex justify-content-center text-center language mt-5">
                                <div
                                    className={type === 1 ? "started-cards-style active" : "started-cards-style"}
                                    onClick={() => setType(1)}
                                >
                                    <img src={Fan} alt={"light"} className={type === 1 ? "darkimg" : "select-img"} />
                                    <p className="light-text pt-3 m-0">FAN</p>
                                </div>
                                <div
                                    className={type === 2 ? "started-cards-style active" : "started-cards-style"}
                                    onClick={() => setType(2)}
                                >
                                    <img
                                        src={Celebrity}
                                        alt={"dark"}
                                        className={type === 2 ? "darkimg" : "select-img"}
                                    />
                                    <p className="light-text pt-3 m-0">INFLUENCER</p>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <Button className="btn btn-primaryy w-100" onClick={() => setScreen(screen + 1)}>
                                    Continue
                                </Button>
                                <br />
                                <Link to="/login" className="btn gotoLogin">
                                    <span>
                                        <BiArrowBack fontSize={"20px"} />
                                    </span>
                                    &nbsp; Already have an account? login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    };

    const allSet = () => {
        return (
            <Wrapper className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <div className="key">
                                <img src={Check} alt="all set" />
                            </div>
                            <div className="page-texts mt-5">
                                <h6>You’re all set!</h6>
                                <p className="mt-12p">You are now ready to explore our features</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-center">
                    <div className="col-lg-12">
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div className="text-center mt-5">
                                <Button className={"btn btn-primaryy w-100"} onClick={() => goto()}>
                                    Open IKnowEm
                                </Button>
                                {/* <div className="account mt-5">
                                    <Link
                                        to="#/"
                                        onClick={() =>
                                            setScreen(
                                                screen - (type === 2 ? 1 : 2)
                                            )
                                        }
                                    >
                                        <span>
                                            <BiArrowBack fontSize={"20px"} />
                                        </span>
                                        &nbsp; Back
                                    </Link>
                                </div> */}
                            </div>
                        )}
                    </div>
                </div>
            </Wrapper>
        );
    };

    const goto = () => {
        navigate("/dashboard");
    };

    const submit = () => {
        // const username = data.username;

        let payload = {
            ...data,
            role: type === 1 ? "fan" : "influencer",

            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Registered Successfully!",
                });
                uploadImage();
                // updateUserName(username);

                // setScreen(screen + 2);
                // if (type === 1) setScreen(screen + 2);
                // else setScreen(screen + 1);
            },
            failure: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
            },
        };
        dispatch(register(payload));
    };

    const updateUserName = (username) => {
        const payload = {
            data: { username },
            success: () => {},
            failure: (error) => {},
        };
        dispatch(update_user_name(payload));
    };
    const chooseFile = () => {
        document.getElementById("file").click();
    };
    const convertHeicToJpeg = async (heicFile) => {
        const jpegBlob = await heic2any({
            blob: heicFile,
            toType: "image/jpeg",
            quality: 1,
        });
        return await blobToImage(jpegBlob);
        return URL.createObjectURL(jpegBlob);
    };
    const selectFile = async (e) => {
        let file = e.target.files;
        let image;
        if (file && file[0]?.name.split(".")[file[0].name.split(".").length - 1] === "heic") {
            image = await blobToImage(file[0]);
        } else {
            image = file[0];
        }

        if (image?.size / 1024 / 1024 > 5) {
            ToastTopRight.fire({
                icon: "error",
                title: "File size should be less than 5MB!",
            });
            return;
        }

        try {
            setImage(image);
            setData({
                ...data,
                profile_pic: window.URL.createObjectURL(image),
            });
        } catch (e) {
            console.log({ e });
            ToastTopRight.fire({
                icon: "error",
                title: "Something Went Wrong!",
            });
        }
    };
    const blobToImage = (blob) => {
        return fetch(blob)
            .then((res) => res.blob())
            .then((myBlob) => {
                const myFile = new File([myBlob], "image.jpeg", {
                    type: "image/jpeg",
                });

                return myFile;
                // logs: Blob { size: 1024, type: "image/jpeg" }
            });
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.onerror = reject;
                img.src = reader.result;
            };
            reader.readAsDataURL(blob);
        });
    };

    const uploadImage = async () => {
        try {
            const form = new FormData();
            form.append("file", image);
            let payload = {
                data: form,
                success: () => {},
                failure: (error) => {
                    ToastTopRight.fire({
                        icon: "error",
                        title: error.msg,
                    });
                },
            };
            try {
                const { data } = await upload_media(payload);
                let obj = {
                    data: { profile_pic: data.file },
                    success: () => {
                        // if (type === 1) {
                        //   navigate("/dashboard");
                        // } else {
                        //   navigate("/dashboard/profile");
                        // }
                        navigate("/dashboard");
                    },
                    failure: (error) => {
                        ToastTopRight.fire({
                            icon: "error",
                            title: error.msg,
                        });
                    },
                };
                dispatch(update_user(obj));
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
            } catch (e) {
                ToastTopRight.fire({
                    icon: "error",
                    title: e.response?.data?.message ? e.response?.data?.message : "Something Went Wrong!",
                });
            }
        } catch (e) {
            ToastTopRight.fire({
                icon: "error",
                title: e.response?.data?.message ? e.response?.data?.message : "Something Went Wrong!",
            });
        }
    };

    return (
        <Wrapper>
            <div className="container-fluid mt-5">
                <div className="centering">
                    {/* {screen === 1 ? <>{ChooseType()}</> : null}
                    {screen === 2 ? <>{SignUp()}</> : null}
                    {screen === 3 ? <>{AddBank()}</> : null}
                    {screen === 4 ? <>{allSet()}</> : null} */}
                    {screen === 1 ? <>{SignUp()}</> : null}
                    {screen === 2 ? <>{AddBank()}</> : null}
                    {screen === 3 ? <>{allSet()}</> : null}
                </div>
                {/* <div className="vertical">
          <div className="circles">
            <div
              className={screen === 1 ? "circle active" : "circle"}
              onClick={() => setScreen(1)}
            ></div>
            <div
              className={screen === 2 ? "circle active" : "circle"}
              onClick={() => setScreen(2)}
            ></div>
            <div
              className={screen === 3 ? "circle active" : "circle"}
              onClick={() => setScreen(3)}
            ></div>
            <div
              className={screen === 4 ? "circle active" : "circle"}
              onClick={() => setScreen(5)}
            ></div>
          </div>
        </div> */}
            </div>
        </Wrapper>
    );
}

export default Signup;
