// Imports: Dependencies
// import { createStore } from "redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

// Imports: Redux Root Reducer
import rootReducer from "./Reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// Imports: Redux Root Saga
import { rootSaga } from "./Saga";

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();

// Redux: Store
// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
let persistor = persistStore(store);
// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

// Exports
export { store, persistor };
