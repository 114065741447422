import { put, call, takeLatest, delay, select } from "redux-saga/effects";
import { ErrorHandler } from "../../utils/errorHandler";
import * as actions from "./actions";
import * as auth_actions from "../Auth/actions";
import {
    getForgotPass,
    get_chats,
    get_packages,
    get_users,
    post_create_chat,
    post_purchase_package,
    post_send_message_fan,
    post_send_message_influencer,
} from "./services";
import { getUser } from "../../selectors";

function* __GETUSERS(action) {
    try {
        const res = yield get_users(action.payload);
        if (res.status === 200) {
            yield put(actions.set_users(res.data.results));
        }
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
    }
}

export function* get_users_saga() {
    yield takeLatest(actions.GET_USERS, __GETUSERS);
}

function* __GETPACKAGES(action) {
    try {
        const res = yield get_packages();
        yield put(actions.set_packages(res.data));
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
    }
}

export function* get_packages_saga() {
    yield takeLatest(actions.GET_PACKAGES, __GETPACKAGES);
}

function* __GETCHATS(action) {
    try {
        yield put(actions.setChatLoading(true));
        const res = yield get_chats();
        yield put(actions.set_chats(res.data.results));
        yield put(actions.setChatLoading(false));
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
        yield put(actions.setChatLoading(false));
    }
}

export function* get_chat() {
    yield takeLatest(actions.GET_CHATS, __GETCHATS);
}

function* __CREATECHAT(action) {
    try {
        const res = yield post_create_chat(action.payload.data);
        action.payload.success(true);
        yield put(auth_actions.get_user());
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
        action.payload.error(errorMessage);
    }
}

export function* create_chat() {
    yield takeLatest(actions.CREATE_CHAT, __CREATECHAT);
}

function* __SENDMESSGETOFAN(action) {
    try {
        const res = yield post_send_message_fan(action.payload.data);
        action.payload.success(true);
        yield put(auth_actions.get_user());
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
        action.payload.error(errorMessage);
    }
}

export function* send_message_fan() {
    yield takeLatest(actions.SEND_MESSAGE_TO_FAN, __SENDMESSGETOFAN);
}

function* __SENDMESSAGETOINFLUENCER(action) {
    try {
        const res = yield post_send_message_influencer(action.payload.data);
        action.payload.success(true);
        yield put(auth_actions.get_user());
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
        action.payload.error(errorMessage);
    }
}

export function* send_message_influencer() {
    yield takeLatest(actions.SEND_MESSAGE_TO_INFLUENCER, __SENDMESSAGETOINFLUENCER);
}

function* __PURCHASEPACKAGE(action) {
    try {
        yield post_purchase_package(action.payload.data);
        yield put(auth_actions.get_user());
        action.payload.success(true);
    } catch (e) {
        const errorMessage = yield call(ErrorHandler, e);
        yield put(actions.set_error({ error: errorMessage }));
        action.payload.error(errorMessage);
    }
}

export function* purchase_package() {
    yield takeLatest(actions.PURCHASE_PACKAGES, __PURCHASEPACKAGE);
}
