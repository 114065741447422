import { axios } from "../../Config/config.js";
import { ErrorHandler } from "../../utils/errorHandler.js";

const get_users = async (payload) => {
    return await axios.get(`users?role=${payload.type}&limit=10000&page=1`);
};

const get_packages = async () => {
    return await axios.get(`packages`);
};

const post_purchase_package = async (payload) => {
    return await axios.post(`bank/purchase-package`, payload);
};

const get_chats = async () => {
    return await axios.get(`chats/?limit=10000&page=1`);
};

const post_create_chat = async (payload) => {
    return await axios.post(`chats/`, payload);
};

const post_send_message_fan = async (payload) => {
    const chatId = payload.chatId;
    delete payload.chatId;
    return await axios.post(`chats/${chatId}/message-to-fan`, payload);
};

const post_send_message_influencer = async (payload) => {
    const chatId = payload.chatId;
    delete payload.chatId;
    return await axios.post(`chats/${chatId}/message-to-influencer`, payload);
};

const add_card_details = async (payload) => {
    try {
        const res = await axios.post(`bank/add-card`, payload);
        return { error: false, data: res.data };
    } catch (e) {
        const errorMessage = ErrorHandler(e);
        return { error: errorMessage };
    }
};

const add_bank_details = async (payload) => {
    try {
        const res = await axios.post(`bank/add-bank`, payload);
        return { error: false, data: res.data };
    } catch (e) {
        const errorMessage = ErrorHandler(e);
        return { error: errorMessage };
    }
};

const withdrawRequest = async (payload) => {
    try {
        const res = await axios.post(`bank/tranfer-init`, payload);
        return { error: false, data: res.data };
    } catch (e) {
        const errorMessage = ErrorHandler(e);
        return { error: errorMessage };
    }
};

export {
    get_users,
    get_packages,
    post_purchase_package,
    add_bank_details,
    add_card_details,
    withdrawRequest,
    get_chats,
    post_create_chat,
    post_send_message_fan,
    post_send_message_influencer
};
