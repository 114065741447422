import styled from "styled-components";

const Wrappper = styled.div`
    .main_div {
        /* min-height: 85vh; */
        display: flex;
        justify-content: center !important;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        font-weight: 400;
        color: var(--color-light);

        .price-plan__annual-price {
            font-size: 30px;
            color: var(--color-light);
        }
        .price-plan__annual-price {
            color: var(--color-dark) !important;
        }
        .price-plan__title {
            font-size: 30px;
            color: var(--color-light);
        }
        li {
            list-style: none;
        }
        h5 {
            color: var(--color-light);
            font-weight: 700;
        }
        p {
            font-weight: 500;
        }
        .effect {
            text-decoration: none;
            font-size: 18px;
            position: relative;
            color: var(--color-light);
        }

        .effect:before {
            content: "";
            width: 0;
            height: 5px;
            background-color: var(--color-dark);
            position: absolute;
            top: 90%;
            left: 0;
            transition: all 0.5s;
        }

        .effect:after {
            content: "";
            width: 0;
            height: 5px;
            background-color: var(--color-light);
            position: absolute;
            top: 90%;
            right: 0;
            transition: all 0.5s;
        }

        /* .effect.active:before {
      width: 40%;
      transform: translateX(50%);
    }

    .effect.active:after {
      width: 35%;
      transform: translateX(-50%);
    } */

        .effect.active {
            background: rgb(204, 204, 204);
        }

        .language .effect:first-child,
        .language .effect:last-child {
            border: 2px solid var(--color-dark);
        }
    }
`;
export default Wrappper;
