import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Forms/Button";
import Input from "../../../components/Forms/Input";
import Spinner from '../../../components/Spinner';
import Wrapper from '../../Authentications/Signup.styled';
import { getUser } from '../../../Store/selectors';
import { change_pass, setLoading } from '../../../Store/Reducers/Auth/actions';
import { ToastTopRight } from '../../../components/Toasts/top-right';

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading,  } = useSelector(getUser);

    useEffect(() => {
        dispatch(setLoading(false));
    }, []);

    const [data, setData] = useState({
        current_password: "",
        password: "",
    });
    const [errField, setErrField] = useState({
        current_password: "",
        password: "",
    });

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setData({ ...data, [name]: value });
    };

    const clearErrors = () => {
        errField.current_password = "";
        errField.password = "";
        setErrField(errField);
    };
    const checkValidation = () => {
        clearErrors();

        var error = false;
        const errForm = { current_password: "", password: "" };
        /* eslint-disable */
        Object.keys(data).map(function (keyName, keyIndex) {
            const value = data[keyName];

            if (keyName === "password") {
                if (value === "") {
                    errForm.password = "ⓘ Password Is Required!";
                    error = true;
                } else if (value.length > 16 || value.length < 8) {
                    errForm.password =
                        "ⓘ Password Should Be Between 8 And 16 Characters!";
                    error = true;
                }
            }

            if (keyName === "current_password") {
                if (value === "") {
                    errForm.current_password = "ⓘ Current Password Is Required!";
                    error = true;
                } 
            }

            setErrField(errForm);
        });
        return error;
    };

    const handleSubmit = async () => {
        dispatch(setLoading(true));
        if (checkValidation()) {
            dispatch(setLoading(false));
            return;
        }

        let payload = {
            ...data,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Updated Successfully!",
                });
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
                dispatch(setLoading(false));
            },
            error: (error) => {
                if(error.code === 401){
                    navigate("/")
                    return
                }
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                dispatch(setLoading(false));
            },
        };
        dispatch(change_pass(payload));
    };
    return <div>
         <Wrapper>
            <div className="container-fluid mt-5">
                <div className="centering">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <div className="get-start-text mt-2 mb-3">
                                        <h6>Change Password</h6>
                                    </div>
                                </div>
                            </div>
                            <center>
                                <div className="flex-column justify-content-center ">
                                    {/* <div className="col-lg-4 mt-3">
                    <Input type={"email"} placeholder="Enter email address" />
                  </div> */}
                                    <div className="col-lg-4 mt-3">
                                        <Input
                                            className={`form-control ${
                                                errField.current_password !== ""
                                                    ? "error-field is-invalid"
                                                    : ""
                                            }`}
                                            type="password"
                                            placeholder="Enter current password"
                                            onChange={handleInput}
                                            name="current_password"
                                        />
                                        {errField.current_password !== "" ? (
                                            <div
                                                className="errors"
                                                style={{ textAlign: "left" }}
                                            >
                                                <span className="form-text text-danger">
                                                    {errField.current_password}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* <div className="col-lg-4 mt-3">
                    <Input type={"password"} placeholder="Enter password" />
                  </div> */}
                                    <div className="col-lg-4 mt-3">
                                        <Input
                                            name="password"
                                            type={"password"}
                                            className={`form-control ${
                                                errField.password !== ""
                                                    ? "error-field is-invalid"
                                                    : ""
                                            }`}
                                            placeholder="Enter new password"
                                            onChange={handleInput}
                                        />
                                        {errField.password !== "" ? (
                                            <div
                                                className="errors"
                                                style={{ textAlign: "left" }}
                                            >
                                                <span className="form-text text-danger">
                                                    {errField.password}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="text-center mt-5">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <>
                                            <Button
                                                className={
                                                    "btn btn-primaryy w-100"
                                                }
                                                onClick={() => handleSubmit()}
                                            >
                                                Update
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    </div>;
}

export default ChangePassword;