import { all } from "@redux-saga/core/effects";
import { fork } from "@redux-saga/core/effects";
import * as Auth from "../Reducers/Auth/saga";
import * as Dashboard from "../Reducers/Dashboard/saga";

export function* rootSaga() {
    const AuthRoots = [
        // Authentications
        fork(Auth.login),
        fork(Auth.logout),
        fork(Auth.register),
        fork(Auth.get_user_saga),
        fork(Auth.update_user),
        fork(Auth.update_user_name),
        fork(Auth.change_password),
        fork(Auth.getCardDetails),
        fork(Auth.removeCardDetails),
        fork(Auth.getBankDetails),
        fork(Auth.removeBankDetails),
        fork(Auth.clearAccounts),

        fork(Dashboard.get_users_saga),
        fork(Dashboard.get_packages_saga),
        fork(Dashboard.purchase_package),
        fork(Dashboard.get_chat),
        fork(Dashboard.create_chat),
        fork(Dashboard.send_message_fan),
        fork(Dashboard.send_message_influencer)
    ];
    yield all([...AuthRoots]);
}
