import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getUser} from '../../Store/selectors'

export default function AdminCheck({children}) {
    const navigate = useNavigate()
    const {user} = useSelector(getUser)
    useEffect(() => {
        if (!user?.id) {
            navigate('/login')
        }
        if (user?.role !== 'admin') {
            navigate('/dashboard')
        }
    }, [user])
    return <div>{children}</div>
}
