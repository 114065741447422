import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastTopRight } from "../../../components/Toasts/top-right";
import { get_bank_details, get_card_details, get_user, setLoading } from "../../../Store/Reducers/Auth/actions";
import { purchase_packages } from "../../../Store/Reducers/Dashboard/actions";
import { getDashboard, getUser } from "../../../Store/selectors";
import Packages from "../Packages/Index";
import Wrappper from "./Wrapper";
import { withdrawRequest } from "../../../Store/Reducers/Dashboard/services";
import CardDetailModal from "../../../components/modals/CardDetailModal";
export default function Wallet() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, users, error, packages } = useSelector(getDashboard);
    const { user, card_details, bank_details } = useSelector(getUser);
    const [card_modal, setCardModal] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCcancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (user.role === "fan") dispatch(get_card_details());
        else dispatch(get_bank_details());
    }, []);

    const pkgPurchase = (pkg) => {
        if (!card_details?.id) {
            ToastTopRight.fire({
                icon: "error",
                title: "Please Add Card Details First!",
            });
            setCardModal(true);
            // navigate("/dashboard/" + user.username);
            return;
        }
        let payload = {
            data: pkg,
            success: () => {
                ToastTopRight.fire({
                    icon: "success",
                    title: "Purchased Successfully !",
                });
                setIsModalVisible(false);
                dispatch(setLoading(false));
            },
            error: (error) => {
                ToastTopRight.fire({
                    icon: "error",
                    title: error.msg,
                });
                dispatch(setLoading(false));
            },
        };
        dispatch(purchase_packages(payload));
    };

    const withdraw = async () => {
        const result = await withdrawRequest();
        if (result.error) {
            ToastTopRight.fire({
                icon: "error",
                text: result.error?.msg,
            });
        } else {
            ToastTopRight.fire({
                icon: "success",
                text: result.data.message,
            });
            dispatch(get_user());
            dispatch(get_bank_details());
        }
    };

    const activateAccount = () => {
        if (bank_details?.id) {
            window.location.replace(bank_details?.connectLink);
        } else {
            setCardModal(true);
        }
    };

    return (
        <Wrappper>
            <Packages
                handleCcancel={handleCcancel}
                isModalVisible={isModalVisible}
                packages={packages}
                pkgPurchase={pkgPurchase}
            />

            {/* CardDetail Modal */}
            <CardDetailModal modal={card_modal} setModal={setCardModal} />

            <div>
                <div class="container-fluid p-0">
                    <div class="iphone">
                        <div class="header d-flex justify-content-between align-items-center">
                            <div class="header-summary">
                                <div class="summary-text">
                                    {user.role === "fan" ? <h3>My Wallet</h3> : <h3>Total Earning</h3>}
                                </div>
                                <div class="summary-balance">$ {user.role === "fan" ? user.credits : user.balance}</div>
                                {/* <div class="summary-text-2">+&nbsp;280.00</div> */}
                            </div>
                            <div class="user-profile">
                                {user.profile_pic ? (
                                    <img src={user.profile_pic} alt="" className="user-photo" />
                                ) : (
                                    <img
                                        src="https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&h=152&fit=crop&crop=faces"
                                        alt=""
                                        className="user-photo"
                                    />
                                )}
                            </div>
                        </div>

                        <div class="cards">
                            {user.role === "fan" ? (
                                <div id="type1">
                                    <div class="btn-4" onClick={() => setIsModalVisible(true)}>
                                        Buy Credits
                                    </div>
                                </div>
                            ) : (
                                <div id="type1">
                                    {bank_details?.status ? (
                                        <div class="btn-4" onClick={withdraw}>
                                            Withdraw Request
                                        </div>
                                    ) : (
                                        <div class="btn-4" onClick={activateAccount}>
                                            Activate Account
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Wrappper>
    );
}
