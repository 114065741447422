import { state as init } from "../../GlobalState";
import * as actions from "./actions";

const reducer = (state = init, action) => {
    switch (action.type) {
        case actions.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
                error: "",
                success_msg: ""
            };
        }
        case actions.SET_LOADING_FALSE: {
            return { ...state, loading: false, error: "" };
        }
        case actions.LOGIN: {
            return {
                ...state,
                loading: true,
                error: "",
                user: action.payload.user
            };
        }
        case actions.LOGIN_FAILURE: {
            return { ...state, loading: false, error: false };
        }

        case actions.GET_CARD_DETAILS: {
            return { ...state, loading: false, ...action.payload };
        }

        case actions.REMOVE_CARD_DETAILS: {
            return { ...state, loading: false, ...action.payload };
        }

        case actions.GET_BANK_DETAILS: {
            return { ...state, loading: false, ...action.payload };
        }

        case actions.REMOVE_BANK_DETAILS: {
            return { ...state, loading: false, ...action.payload };
        }

        case actions.UPDATE_USER: {
            return { ...state, loading: false, ...action.payload };
        }

        case actions.LOGIN_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                ...payload,
                error: false
            };
        }
        case actions.SET_STATE: {
            const payload = action.payload;
            return {
                ...state,
                ...payload
            };
        }

        // LOGOUT
        case actions.LOGOUT: {
            return { ...init };
        }
        // LOGOUT END

        // REGISTER
        case actions.REGISTER: {
            return { ...state, loading: true, error: "", user: action };
        }
        case actions.REGISTER_FAILURE: {
            return { ...state, loading: false, error: action.payload.error };
        }
        case actions.REGISTER_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: ""
                // token: action.payload.token,
                // user: action.payload.user,
            };
        }
        // REGISTER END

        // FORGOT PASSWORD
        case actions.FORGOT_PASSWORD: {
            return { ...state, loading: true, error: "" };
        }
        case actions.FORGOT_PASSWORD_SUCCESS: {
            return { ...state, loading: false, error: "" };
        }

        case actions.OTP_SUCCESS: {
            return { ...state, error: "" };
        }

        case actions.SET_NEW_PASSWORD: {
            return { ...state, error: "" };
        }

        case actions.SEND_VERIFY_EMAIL: {
            return { ...state, loading: false };
        }
        case actions.VERIFY_EMAIL: {
            return { ...state, loading: false };
        }

        case actions.VERIFY_EMAIL_CODE: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

export default reducer;
