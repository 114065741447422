import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;    
    width: 100%;
    position: relative;
    font-size: 16px;
    input {
        outline: none   
    }
    span {
        position: absolute;
        top: 55%;
    }
    .leftIcon {
        left: 10px;
        transform: translate(-50%, -40%);
    }
    .rightIcon {
        right: 5%;
        transform: translate( 50%, -50%);
        :hover {
            cursor: pointer;
        }
    }
    @media (max-width: 600px) {
        .rightIcon {
            right: 8%;
        }
    }
`

export const Inputitem = styled.input`
    
    width: inherit;
    height: 45px;
    padding: 10px 15px;
    padding-right: ${props => props.paddingright ? "60px" : "30px"};
    border: 1px solid lightgrey;
    border-radius: 8px;
    outline: none;
    ::placeholder {
        color: #667085;
        font-size: 16px;
        font-weight: 400;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`

export const SecondWrapper = styled.div`
    display: flex;
    justify-content: center;    
    width: 100%;
    position: relative;
    font-size: 16px;
    input {
        outline: none   
    }
    span {
        position: absolute;
        top: 55%;
    }
    .leftIcon {
        left: 10px;
        transform: translate(-50%, -40%);
    }
    .rightIcon {
        right: 5%;
        transform: translate( 50%, -50%);
        :hover {
            cursor: pointer;
        }
    }
    @media (max-width: 600px) {
        .rightIcon {
            right: 8%;
        }
    }
`

export const SecondInputitem = styled.input`
    
    width: inherit;
    height: 50px;
    padding: 10px 15px;
    padding-right: ${props => props.paddingright ? "60px" : "30px"};
    border: 2px solid #fff;
    border-radius: 8px;
    outline: none;
    background: #f09ad9;
  
    ::placeholder {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`